import React, { useContext } from 'react'

import Swal from 'sweetalert2';

import lupa from '../../assets/ver.svg'
import tacho from '../../assets/borrar.svg'
import { AuthContext } from '../../auth/AuthContext';
import { useHistory } from 'react-router-dom';
import { eliminarEnvio } from '../../services/adminRequest';

export const AdminRowEnvio = ({ dataEnvios }) => {

   const { data, setData } = useContext(AuthContext)
   const history = useHistory()


   const handleSelectEnvio = (envioSelect) => {

      localStorage.setItem("envioActive", JSON.stringify({ ...envioSelect }))

      setData({
         ...data,
         administrador: {
            ...data.administrador,
            envioActive: { ...envioSelect }
         }
      })

      history.push(`/admin/envios/${envioSelect.codigo}`)


   }

   const handleEliminarUsuario = (idEnvio) => {

      Swal.fire({
         title: 'Eliminar envio',
         text: 'Estas seguro que deseas eliminarlo?',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         cancelButtonText: "Cancelar",
      }).then((result) => {
         if (result.isConfirmed) {

            eliminarEnvio(idEnvio)
               .then(resp => {

                  if (resp.ok) {

                     setData((state) => ({
                        ...state,
                        administrador: {
                           ...state.administrador,
                           envios: state.administrador.envios.filter(envio => envio.id !== idEnvio)
                        }
                     }))

                     Swal.fire(
                        'Eliminado!',
                        `El envio ha sido eliminado.`,
                        'success'
                     )

                  }
                  else {
                     Swal.fire(
                        'Ups!',
                        resp.msg ?? "Error en eliminar, vuelva intentarlo o comuniquese con el administrador",
                        'error'
                     )
                  }
               })
         }
      })


   }

   return (
      <>
         {dataEnvios.map(envio => (
            <tr className="animate__animated animate__fadeIn" key={envio.codigo}>
               <td className="font-rubik">{envio.codigo}</td>
               <td className="font-rubik">{envio.desde}</td>
               <td className="font-rubik">{envio.hasta}</td>
               <td className="font-rubik">{envio.fregistro}</td>
               <td className="font-rubik">{envio.usuariocorreo}</td>
               <td className="td__documento">
                  <div onClick={() => {
                     handleSelectEnvio(envio)
                  }}>
                     <img src={lupa} alt="lupa" />
                  </div>
                  <div onClick={() => {
                     handleEliminarUsuario(envio.id)
                  }}>
                     <img src={tacho} alt="tacho" />
                  </div>
               </td>
            </tr>
         ))}
      </>
   )
}
