import React, { useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthContext'
import './LoginScreen.css'
import logo from '../../assets/logo.svg'
import Swal from 'sweetalert2'
import { sendLogin } from '../../services/sendLogin'

export const LoginScreen = ({ isLogged }) => {

   const { setData } = useContext(AuthContext)

   const history = useHistory()

   const [formValues, setFormValues] = useState({
      correo: '',
      contrasena: ''
   })

   const { correo, contrasena } = formValues

   const hadleChange = ({ target }) => {
      setFormValues({
         ...formValues,
         [target.name]: target.value
      })
   }

   const handleSubmit = async (ev) => {

      ev.preventDefault()

      Swal.fire({
         title: "Cargando..",
         text: "Por favor esperar",
         allowOutsideClick: false,
         didOpen: () => {
            Swal.showLoading()
         }


      })

      try {

         const dataLogin = await sendLogin(formValues)

         if (!dataLogin.ok && !dataLogin.msg) {

            Swal.close()

            Swal.fire({
               title: 'Error',
               text: 'No tiene una cuenta en la plataforma',
               icon: 'error',
               confirmButtonColor: '#3daf2c'
            })
            return false
         }

         if (!dataLogin.ok && dataLogin.msg) {

            Swal.close()

            Swal.fire({
               title: 'Error',
               text: dataLogin.msg,
               icon: 'error',
               confirmButtonColor: '#3daf2c'
            })
            return false
         }

         const usuarioTipo = dataLogin.tipo


         if (usuarioTipo === "natural" || usuarioTipo === "empresa") {

            localStorage.setItem("token", dataLogin.token)
            localStorage.setItem("token-init-date", new Date().getTime())

            const datosPersonales = { ...dataLogin }

            delete datosPersonales.ok
            delete datosPersonales.token
            delete datosPersonales.contrasena

            localStorage.setItem("usuarioactive", JSON.stringify(datosPersonales))

            setData((state) => ({
               ...state,
               login: {
                  logged: true,
                  name: dataLogin.nombre,
                  codigo: dataLogin.codigo,
                  rol: dataLogin.tipo,
                  token: dataLogin.token
               },
               usuario: {
                  datosPersonales
               }
            }))

            Swal.close()

            history.replace("/panel")
         }

         else if (usuarioTipo === "administrador") {

            localStorage.setItem("token", dataLogin.token)
            localStorage.setItem("token-init-date", new Date().getTime()) //guardamos la fecha exacta en la que guardamos el token

            setData((state) => ({
               ...state,
               login: {
                  logged: true,
                  name: dataLogin.nombre,
                  codigo: dataLogin.codigo,
                  rol: dataLogin.tipo,
                  token: dataLogin.token
               }
            }))

            Swal.close()

            history.replace("/admin")
         }

         else {

            Swal.close()

            Swal.fire({
               title: 'Error',
               text: 'Su cuenta no tiene un tipo asignado',
               icon: 'error',
               confirmButtonColor: '#3daf2c'
            })
         }

      } catch (error) {

         Swal.close()

         Swal.fire({
            title: 'Error',
            text: 'Error en la peticion de conexion y/o server error',
            icon: 'error',
            confirmButtonColor: '#3daf2c'
         })
      }


      // history.push("/panel")


   }

   return (
      <div className="login__contenedor">

         <div className="login__logo">
            <img src={logo} alt="logo" />
         </div>

         <div className="login__contenido-formulario">

            <h2 className="login__titulo font-rubik">Bienvenido(a)</h2>
            <p className="login__subtitulo font-rubik">Ingresa a la plataforma con tus datos de acceso</p>

            <form className="login__tag-formulario" autoComplete="off" onSubmit={handleSubmit}>

               <div className="login__formulario-group">
                  <label htmlFor="correo" className="font-rubik">Ingresa tu número de documento</label>
                  <input type="text" name="correo" id="correo" onChange={hadleChange} value={correo} className="font-rubik" required />
               </div>

               <div className="login__formulario-group">
                  <label htmlFor="contrasena" className="font-rubik">Ingresa tu contraseña</label>
                  <input type="password" name="contrasena" onChange={hadleChange} value={contrasena} className="font-rubik" required />
               </div>

               <div className="login__formulario-group login__formulario-group--buttons">
                  <button
                     type="submit"
                     className="font-rubik"
                  >Iniciar sesión</button>
                  <Link className="login__formulario-olvidastes-contrasena font-rubik" to="/recovery">¿Olvidaste tu contraseña?</Link>
               </div>

            </form>

         </div>

         <div className="login__pie-pagina">
            <p className="font-rubik">¿No tienes una cuenta? <a href="mailto:ventas@urbexapp.com" Subject="Solicitar%20una%20cuenta">Solicítala aquí</a></p>
         </div>

      </div>
   )
}
