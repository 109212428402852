import React from 'react'

import { EntregadoIcono } from './EntregadoIcono'
import { EnviadoIcono } from './EnviadoIcono'
import { ProcesadoIcono } from './ProcesadoIcono'

export const EstadoEnvio = ({ estado }) => {

   return (
      <div className="urbex__estado-envio">

         <div className={(estado === "procesando" || estado === "enviando" || estado === "entregado") ? 'active' : ''}>
            <ProcesadoIcono></ProcesadoIcono>
            <div className="texto">Procesando</div>
         </div>

         <div className={(estado === "enviando" || estado === "entregado") ? 'active' : ''}>
            <EnviadoIcono></EnviadoIcono>
            <div className="texto">Enviando</div>
         </div>

         <div className={(estado === "entregado") ? 'active' : ''}>
            <EntregadoIcono></EntregadoIcono>
            <div className="texto">Entregado</div>
         </div>
      </div>
   )
}
