import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../auth/AuthContext'
import { obtenerEnvios } from '../../services/usuarioRequest'


export const PaginationTablePublic = ({ cantidadPaginas, setEnvios }) => {

   const [contPag, setContPag] = useState([])

   const { data: { usuario: { datosPersonales } } } = useContext(AuthContext)

   useEffect(() => {

      let paginas = Math.ceil(cantidadPaginas / 20)

      setContPag(Array.from(Array(paginas)).map((_, i) => i + 1))

   }, [cantidadPaginas])

   const cambiarPaginaDatos = async (paginas) => {

      const response = await obtenerEnvios(paginas, datosPersonales.id)

      if (response.ok) {

         let paginas = Math.ceil(response.cdt / 20)

         setContPag(Array.from(Array(paginas)).map((_, i) => i + 1))

         setEnvios(response.data)

      }

   }

   if (contPag.length === 1) {
      return ''
   }


   return (
      <div className="urbex__paginacion">
         <ul>
            {contPag.map(pag => (
               <li
                  key={pag}
                  onClick={() => { cambiarPaginaDatos(pag) }}
               >{pag}</li>
            ))}
         </ul>
      </div>
   )
}
