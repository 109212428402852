export const obtenerEnvios = (pag = 1, usuarioid) => {

   const baseURL = process.env.REACT_APP_API_URL

   const apiURL = `${baseURL}/envios/usuariomostrarenvios/${usuarioid}/?pag=${pag}`

   const token = localStorage.getItem("token") || ''

   return fetch(apiURL, {
      headers: {
         "x-token": token
      }
   })
      .then(response => response.json())
      .then(resp => {

         return resp
      })
}

export const actualizarDatosPersonales = (usuario) => {

   const baseURL = process.env.REACT_APP_API_URL

   const apiURL = `${baseURL}/usuario/actualizardatos`

   const token = localStorage.getItem("token") || ''

   return fetch(apiURL, {
      method: "PUT",
      body: JSON.stringify(usuario),
      headers: {
         "x-token": token,
         "Content-Type": "application/json",
      }
   })
      .then(response => response.json())
      .then(resp => resp)
}