import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Redirect } from 'react-router-dom'
import Swal from 'sweetalert2'
// import { useLocation } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthContext'
import { actualizarUsuario } from '../../services/adminRequest'

export const UsuarioInformacion = () => {

   const history = useHistory()

   const { data: { administrador: { usuarioActive } } } = useContext(AuthContext)

   const [tipoUsurio, setTipoUsurio] = useState(false)

   const [formValues, setFormValues] = useState(
      (usuarioActive) ?
         {
            ...usuarioActive,
            contrasena: '',
            contrasena2: ''
         }
         :
         { ...JSON.parse(localStorage.getItem("usuarioActive")), contrasena: '', contrasena2: '' }
   )
   const { codigo, nombre, apellidos, tipo, ruc, razon_social, tipodoc, documento, telefono, correo, contrasena, contrasena2 } = formValues

   useEffect(() => {

      if (tipo === "empresa") {
         setTipoUsurio(true)
      }
      else {
         setTipoUsurio(false)
      }

   }, [tipo])

   const handleFormChange = ({ target }) => {

      if (target.name === "tipo" && target.value === "natural") {
         setFormValues({
            ...formValues,
            ruc: '',
            razon_social: '',
            [target.name]: target.value
         })
      }
      else {
         setFormValues({
            ...formValues,
            [target.name]: target.value
         })

      }

   }

   const handleSubmit = (ev) => {

      ev.preventDefault()

      if (contrasena !== contrasena2) {

         Swal.fire({
            title: 'Error',
            text: 'Las contraseñas no coinciden.',
            icon: 'error',
            confirmButtonColor: '#3daf2c'
         })

         return false

      }

      Swal.fire({
         title: "Cargando..",
         text: "Por favor esperar",
         allowOutsideClick: false,
         didOpen: () => {
            Swal.showLoading()
         }

      })

      actualizarUsuario(formValues)
         .then(response => {

            if (!response.ok && !response.msg) {

               if (response.errors.contrasena || response.errors.correo) {

                  Swal.close()

                  Swal.fire({
                     title: 'Error',
                     text: (response.errors.contrasena) ? response.errors.contrasena.msg : response.errors.correo.msg,
                     icon: 'error',
                     confirmButtonColor: '#3daf2c'
                  })

                  return false
               }
            }

            Swal.close()

            Swal.fire(
               '',
               response.msg,
               'success'
            )
               .then(result => {
                  if (result.isConfirmed) {
                     localStorage.removeItem("usuarioActive")
                     history.replace("/admin/")
                  }
               })
         })

   }


   if (!usuarioActive && !localStorage.getItem("usuarioActive")) {
      return <Redirect to="/admin"></Redirect>
   }


   return (
      <div className="urbex__contenido-envio urbex__usuario-contenido">

         <h1 className="urbex__titulo font-rubik">{nombre} {apellidos}</h1>

         <div className="urbex__form-profile">

            <form onSubmit={handleSubmit} >

               <div className="urbex__form-group">
                  <label className="urbex__label-titulo">Tipo de usuario: </label>
                  <div className="urbex__inputs urbex__inputs-radio">

                     <div className="urbex__radio">
                        <input name="tipo" id="tipo_usuario_natural" value="natural" checked={tipo === "natural"} onChange={handleFormChange} type="radio" /> <label htmlFor="tipo_usuario_natural">Persona Natural</label>
                     </div>
                     <div className="urbex__radio">
                        <input name="tipo" id="tipo_usuario_empresa" value="empresa" checked={tipo === "empresa"} onChange={handleFormChange} type="radio" /> <label htmlFor="tipo_usuario_empresa">Empresa</label>
                     </div>

                  </div>
               </div>

               <div className="urbex__form-inputs-full">
                  <div className="urbex__input-full">
                     <label htmlFor="codigo">Codigo de usuario</label>
                     <span className="inputdisabled">{codigo}</span>
                  </div>
               </div>

               {
                  tipoUsurio &&
                  <div className="urbex__form-group">
                     <div className="urbex__inputs">
                        <label htmlFor="ruc">RUC</label>
                        <input type="text" name="ruc" value={ruc} onChange={handleFormChange} id="ruc" />
                     </div>
                     <div className="urbex__inputs">
                        <label htmlFor="razsocial">Razon Social</label>
                        <input type="text" name="razon_social" value={razon_social} onChange={handleFormChange} id="razsocial" />
                     </div>
                  </div>
               }

               <div className="urbex__form-group">
                  <div className="urbex__inputs">
                     <label htmlFor="nombres">Nombres</label>
                     <input type="text" value={nombre} onChange={handleFormChange} name="nombre" id="nombres" />
                  </div>
                  <div className="urbex__inputs">
                     <label htmlFor="apellido">Apellidos</label>
                     <input type="text" value={apellidos} onChange={handleFormChange} name="apellidos" id="apellido" />
                  </div>
               </div>
               <div className="urbex__form-group">
                  <div className="urbex__inputs">
                     <label htmlFor="tipodoc">Tipo de Documento</label>

                     <select name="tipodoc" value={tipodoc} onChange={handleFormChange} id="tipodoc" required>
                        <option value="" disabled>Tipo de documento</option>
                        <option value="dni">DNI</option>
                        <option value="carnet-extranjeria">Carnet De Extranjeria</option>
                        <option value="reg-unico-contribuyente">Reg. Unico De Contribuyentes</option>
                        <option value="pasaporte">Pasaporte</option>
                        <option value="otros">Otros</option>
                     </select>

                  </div>
                  <div className="urbex__inputs">
                     <label htmlFor="documento">Nro. de Documento</label>
                     <input type="text" name="documento" value={documento} onChange={handleFormChange} id="documento" />
                  </div>

               </div>
               <div className="urbex__form-group">
                  <div className="urbex__inputs">
                     <label htmlFor="telefono">Teléfono</label>
                     <input type="text" name="telefono" value={telefono} onChange={handleFormChange} id="telefono" />
                  </div>
                  <div className="urbex__inputs">
                     <label htmlFor="correo">Correo Electrónico</label>
                     <input type="text" name="correo" value={correo} onChange={handleFormChange} id="correo" required />
                  </div>

               </div>

               <div className="urbex__linea-gruesa"></div>

               <div className="urbex__form-group">

                  <div className="urbex__inputs">
                     <label htmlFor="contrasena">Cambiar contraseña</label>
                     <input type="password" name="contrasena" value={contrasena} onChange={handleFormChange} id="contrasena" />
                  </div>
                  <div className="urbex__inputs">
                     <label htmlFor="contrasena2">Repetir contraseña nueva</label>
                     <input type="password" name="contrasena2" value={contrasena2} onChange={handleFormChange} id="contrasena22" />
                  </div>

               </div>

               <div className="urbex__form-group-button">
                  <button type="submit">Actualizar</button>
               </div>

            </form>

         </div>

      </div>
   )
}
