import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { AuthContext } from '../../auth/AuthContext';
import './ui.css';
import { UiContext } from './UiContext';

export const NavBar = () => {

   const history = useHistory()

   const { data, setData } = useContext(AuthContext)
   const { setDataUI } = useContext(UiContext)

   const { login } = data

   const handleCerrarSession = () => {

      localStorage.clear()

      setData({
         login: {
            logged: false,
         },
         administrador: {
            usuarios: [],
            envios: []
         }
      })

      history.replace("/")
   }


   const handleMostrarMenu = () => {
      setDataUI((state) => ({
         ...state,
         mostrarSideBar: !state.mostrarSideBar
      }))
   }

   return (
      <div className="urbex__navbar">

         <span className="urbex__icono-menu-responsive" onClick={handleMostrarMenu}><i className="fas fa-bars"></i></span>

         <ul className="urbex__menu">
            <li className="urbex__menu-item font-rubik">Hola, {login.name}</li>
            <li className="urbex__menu-item" onClick={handleCerrarSession}><span><i className="fas fa-sign-out-alt"></i></span><span>&nbsp; Cerrar sesión</span></li>
         </ul>

      </div>
   )
}
