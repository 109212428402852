export function recuperarPassword(correo) {

   const baseURL = process.env.REACT_APP_API_URL

   const apiURL = `${baseURL}/auth/recuperarcontrasena`

   return fetch(apiURL, {
      method: 'POST',
      headers: {
         "Content-Type": "application/json",
      },
      body: JSON.stringify({ correo })
   })
      .then(response => response.json())
      .then(resp => resp)
}

export function validarToken(token) {

   const baseURL = process.env.REACT_APP_API_URL

   const apiURL = `${baseURL}/auth/validarToken`

   return fetch(apiURL, {
      method: 'POST',
      headers: {
         "x-token": token
      }
   })
      .then(response => response.json())
      .then(resp => resp)
}

export function cambiarContrasena({ contrasena, contrasena2, token }) {

   const baseURL = process.env.REACT_APP_API_URL

   const apiURL = `${baseURL}/auth/cambiarcontrasena`

   return fetch(apiURL, {
      method: 'POST',
      headers: {
         "x-token": token,
         "Content-Type": "application/json",
      },
      body: JSON.stringify({
         contrasena, contrasena2
      })
   })
      .then(response => response.json())
      .then(resp => resp)
}