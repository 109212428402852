import React, { useContext } from 'react'

// importamos reac-router-dom
import {
   BrowserRouter as Router,
   Switch,
   Route,
   Redirect
} from "react-router-dom";
import { AuthContext } from '../auth/AuthContext';

// importacion de componentes
import { LoginScreen } from '../components/login/LoginScreen';
import { RecuperarPassword } from '../components/login/RecuperarPassword';
import { DashboardRouter } from './DashboardRouter';
import { AdminDashboardRouter } from './AdminDashboardRouter';
import { CambiarContrasena } from '../components/login/CambiarContrasena';
// import { getTokenRenew } from '../services/getTokenRenew';


export const AppRouter = () => {

   const { data } = useContext(AuthContext)

   const { login } = data

   return (
      <Router>
         <div className={login.logged ? 'contenedor' : ''}>
            {/* <div className='contenedor'> */}
            <Switch>
               <Route exact path="/" component={LoginScreen}></Route>
               <Route exact path="/recovery" component={RecuperarPassword}></Route>
               <Route exact path="/cambiar-contrasena" component={CambiarContrasena}></Route>
               <Route path="/panel" component={DashboardRouter}></Route>
               <Route path="/admin" component={AdminDashboardRouter}></Route>

               <Redirect to="/"></Redirect>
            </Switch>
         </div>
      </Router>
   )
}
