export const departamentos = [
   {
      "id_ubigeo": "2534",
      "nombre_ubigeo": "Amazonas",
      "codigo_ubigeo": "01",
      "etiqueta_ubigeo": "Amazonas, Per\u00fa",
      "buscador_ubigeo": "amazonas per\u00fa",
      "numero_hijos_ubigeo": "7",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "2625",
      "nombre_ubigeo": "Ancash",
      "codigo_ubigeo": "02",
      "etiqueta_ubigeo": "Ancash, Per\u00fa",
      "buscador_ubigeo": "ancash per\u00fa",
      "numero_hijos_ubigeo": "20",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "2812",
      "nombre_ubigeo": "Apurimac",
      "codigo_ubigeo": "03",
      "etiqueta_ubigeo": "Apurimac, Per\u00fa",
      "buscador_ubigeo": "apurimac per\u00fa",
      "numero_hijos_ubigeo": "7",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "2900",
      "nombre_ubigeo": "Arequipa",
      "codigo_ubigeo": "04",
      "etiqueta_ubigeo": "Arequipa, Per\u00fa",
      "buscador_ubigeo": "arequipa per\u00fa",
      "numero_hijos_ubigeo": "8",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "3020",
      "nombre_ubigeo": "Ayacucho",
      "codigo_ubigeo": "05",
      "etiqueta_ubigeo": "Ayacucho, Per\u00fa",
      "buscador_ubigeo": "ayacucho per\u00fa",
      "numero_hijos_ubigeo": "11",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "3143",
      "nombre_ubigeo": "Callao",
      "codigo_ubigeo": "06",
      "etiqueta_ubigeo": "Callao, Per\u00fa",
      "buscador_ubigeo": "Callao per\u00fa",
      "numero_hijos_ubigeo": "13",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "3144",
      "nombre_ubigeo": "Cajamarca",
      "codigo_ubigeo": "06",
      "etiqueta_ubigeo": "Cajamarca, Per\u00fa",
      "buscador_ubigeo": "cajamarca per\u00fa",
      "numero_hijos_ubigeo": "13",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "3292",
      "nombre_ubigeo": "Cusco",
      "codigo_ubigeo": "08",
      "etiqueta_ubigeo": "Cusco, Per\u00fa",
      "buscador_ubigeo": "cusco per\u00fa",
      "numero_hijos_ubigeo": "13",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "3414",
      "nombre_ubigeo": "Huancavelica",
      "codigo_ubigeo": "09",
      "etiqueta_ubigeo": "Huancavelica, Per\u00fa",
      "buscador_ubigeo": "huancavelica per\u00fa",
      "numero_hijos_ubigeo": "7",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "3518",
      "nombre_ubigeo": "Huanuco",
      "codigo_ubigeo": "10",
      "etiqueta_ubigeo": "Huanuco, Per\u00fa",
      "buscador_ubigeo": "huanuco per\u00fa",
      "numero_hijos_ubigeo": "11",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "3606",
      "nombre_ubigeo": "Ica",
      "codigo_ubigeo": "11",
      "etiqueta_ubigeo": "Ica, Per\u00fa",
      "buscador_ubigeo": "ica per\u00fa",
      "numero_hijos_ubigeo": "5",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "3655",
      "nombre_ubigeo": "Junin",
      "codigo_ubigeo": "12",
      "etiqueta_ubigeo": "Junin, Per\u00fa",
      "buscador_ubigeo": "junin per\u00fa",
      "numero_hijos_ubigeo": "9",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "3788",
      "nombre_ubigeo": "La Libertad",
      "codigo_ubigeo": "13",
      "etiqueta_ubigeo": "La Libertad, Per\u00fa",
      "buscador_ubigeo": "la libertad per\u00fa",
      "numero_hijos_ubigeo": "12",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "3884",
      "nombre_ubigeo": "Lambayeque",
      "codigo_ubigeo": "14",
      "etiqueta_ubigeo": "Lambayeque, Per\u00fa",
      "buscador_ubigeo": "lambayeque per\u00fa",
      "numero_hijos_ubigeo": "3",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "3926",
      "nombre_ubigeo": "Lima",
      "codigo_ubigeo": "15",
      "etiqueta_ubigeo": "Lima, Per\u00fa",
      "buscador_ubigeo": "lima per\u00fa",
      "numero_hijos_ubigeo": "10",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "4108",
      "nombre_ubigeo": "Loreto",
      "codigo_ubigeo": "16",
      "etiqueta_ubigeo": "Loreto, Per\u00fa",
      "buscador_ubigeo": "loreto per\u00fa",
      "numero_hijos_ubigeo": "6",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "4165",
      "nombre_ubigeo": "Madre de Dios",
      "codigo_ubigeo": "17",
      "etiqueta_ubigeo": "Madre de Dios, Per\u00fa",
      "buscador_ubigeo": "madre de dios per\u00fa",
      "numero_hijos_ubigeo": "3",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "4180",
      "nombre_ubigeo": "Moquegua",
      "codigo_ubigeo": "18",
      "etiqueta_ubigeo": "Moquegua, Per\u00fa",
      "buscador_ubigeo": "moquegua per\u00fa",
      "numero_hijos_ubigeo": "3",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "4204",
      "nombre_ubigeo": "Pasco",
      "codigo_ubigeo": "19",
      "etiqueta_ubigeo": "Pasco, Per\u00fa",
      "buscador_ubigeo": "pasco per\u00fa",
      "numero_hijos_ubigeo": "3",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "4236",
      "nombre_ubigeo": "Piura",
      "codigo_ubigeo": "20",
      "etiqueta_ubigeo": "Piura, Per\u00fa",
      "buscador_ubigeo": "piura per\u00fa",
      "numero_hijos_ubigeo": "8",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "4309",
      "nombre_ubigeo": "Puno",
      "codigo_ubigeo": "21",
      "etiqueta_ubigeo": "Puno, Per\u00fa",
      "buscador_ubigeo": "puno per\u00fa",
      "numero_hijos_ubigeo": "13",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "4431",
      "nombre_ubigeo": "San Martin",
      "codigo_ubigeo": "22",
      "etiqueta_ubigeo": "San Martin, Per\u00fa",
      "buscador_ubigeo": "san martin per\u00fa",
      "numero_hijos_ubigeo": "10",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "4519",
      "nombre_ubigeo": "Tacna",
      "codigo_ubigeo": "23",
      "etiqueta_ubigeo": "Tacna, Per\u00fa",
      "buscador_ubigeo": "tacna per\u00fa",
      "numero_hijos_ubigeo": "4",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "4551",
      "nombre_ubigeo": "Tumbes",
      "codigo_ubigeo": "24",
      "etiqueta_ubigeo": "Tumbes, Per\u00fa",
      "buscador_ubigeo": "tumbes per\u00fa",
      "numero_hijos_ubigeo": "3",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   },
   {
      "id_ubigeo": "4567",
      "nombre_ubigeo": "Ucayali",
      "codigo_ubigeo": "25",
      "etiqueta_ubigeo": "Ucayali, Per\u00fa",
      "buscador_ubigeo": "ucayali per\u00fa",
      "numero_hijos_ubigeo": "4",
      "nivel_ubigeo": "1",
      "id_padre_ubigeo": "2533"
   }
]