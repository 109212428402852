import React, { useContext } from 'react'

import { NavLink } from 'react-router-dom';
import logo from '../../assets/logo.svg'

import './ui.css';
import { UiContext } from './UiContext';

export const SideBarAdmin = () => {

   const { dataUI: { mostrarSideBar }, setDataUI } = useContext(UiContext)

   const handleCerrarMenu = () => {
      setDataUI((state) => ({
         ...state,
         mostrarSideBar: false
      }))
   }

   return (
      <div className={(mostrarSideBar) ? 'urbex__sidebar urbex__sidebar-admin active' : 'urbex__sidebar urbex__sidebar-admin'}>
         <div className="urbex__sidebar-logo">
            <img src={logo} alt="logo" />
         </div>

         <div className="urbex__sidebar-titulo urbex__sidebar-titulo-admin">
            <p>Administrador</p>
         </div>

         <div className="urbex__sidebar-menu">
            <NavLink activeClassName="active" exact to="/admin" onClick={handleCerrarMenu}><i className="fas fa-user"></i> Usuarios</NavLink>
            <NavLink activeClassName="active" exact to="/admin/envios" onClick={handleCerrarMenu}><i className="fas fa-truck"></i> Envíos</NavLink>
         </div>
      </div>
   )
}
