import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../auth/AuthContext'
import { obtenerEnvios, obtenerUsuarios } from '../../services/adminRequest'
import './dashboardScreen.css'

export const PaginationTable = ({ cantidadPaginas, tipo }) => {

   const [contPag, setContPag] = useState([])
   const { setData } = useContext(AuthContext)

   useEffect(() => {

      let paginas = Math.ceil(cantidadPaginas / 20)

      setContPag(Array.from(Array(paginas)).map((_, i) => i + 1))

   }, [cantidadPaginas])

   const cambiarPaginaDatos = async (paginas) => {

      let response = ''

      if (tipo === "envios") {

         response = await obtenerEnvios(paginas)

      }
      else {

         response = await obtenerUsuarios(paginas)
      }

      if (response.ok) {

         let paginas = Math.ceil(response.cdt / 20)

         setContPag(Array.from(Array(paginas)).map((_, i) => i + 1))

         setData((store) => ({
            ...store,
            administrador: {
               ...store.administrador,
               [tipo]: response.data
            }
         }))

      }

   }

   if (contPag.length === 1) {
      return ''
   }


   return (
      <div className="urbex__paginacion">
         <ul>
            {contPag.map(pag => (
               <li
                  key={pag}
                  onClick={() => { cambiarPaginaDatos(pag) }}
               >{pag}</li>
            ))}
         </ul>
      </div>
   )
}
