import React from 'react'

export const EnviadoIcono = () => {
   return (
      <div className="urbex__icono-logo">
         <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.75 3C6.84375 3 4.5 5.39062 4.5 8.25C4.5 11.1562 6.84375 13.5 9.75 13.5C12.6094 13.5 15 11.1562 15 8.25C15 5.39062 12.6094 3 9.75 3ZM9.75 12C7.64062 12 6 10.3594 6 8.25C6 6.1875 7.64062 4.5 9.75 4.5C11.8125 4.5 13.5 6.1875 13.5 8.25C13.5 10.3594 11.8125 12 9.75 12ZM11.25 7.5H10.5V6C10.5 5.625 10.125 5.25 9.75 5.25C9.32812 5.25 9 5.625 9 6V8.25C9 8.67188 9.32812 9 9.75 9H11.25C11.625 9 12 8.67188 12 8.25C12 7.875 11.625 7.5 11.25 7.5ZM29.25 18H28.5V11.625C28.5 10.7344 28.1719 9.89062 27.5625 9.1875L24.2344 5.29688C23.8125 4.82812 23.2031 4.5 22.5469 4.5H19.5V3C19.5 1.35938 18.1406 0 16.5 0H3C1.3125 0 0 1.35938 0 3V16.5C0 18.1875 1.3125 19.5 3 19.5C3 21.9844 5.01562 24 7.5 24C9.9375 24 12 21.9844 12 19.5H18C18 21.9844 20.0156 24 22.5 24C24.9375 24 27 21.9844 27 19.5H29.25C29.625 19.5 30 19.1719 30 18.75C30 18.375 29.625 18 29.25 18ZM19.5 6H22.5469C22.7344 6 22.9688 6.14062 23.1094 6.28125L26.4375 10.1719C26.5312 10.2656 26.625 10.4062 26.6719 10.5H19.5V6ZM7.5 22.5C5.8125 22.5 4.5 21.1875 4.5 19.5C4.5 17.8594 5.8125 16.5 7.5 16.5C9.14062 16.5 10.5 17.8594 10.5 19.5C10.5 21.1875 9.14062 22.5 7.5 22.5ZM22.5 22.5C20.8125 22.5 19.5 21.1875 19.5 19.5C19.5 17.8594 20.8125 16.5 22.5 16.5C24.1406 16.5 25.5 17.8594 25.5 19.5C25.5 21.1875 24.1406 22.5 22.5 22.5ZM27 18H26.7188C26.1094 16.2656 24.4219 15 22.5 15C20.5312 15 18.8438 16.2656 18.2344 18H11.7188C11.1094 16.2656 9.42188 15 7.5 15C5.53125 15 3.84375 16.2656 3.23438 18H3C2.15625 18 1.5 17.3438 1.5 16.5V3C1.5 2.20312 2.15625 1.5 3 1.5H16.5C17.2969 1.5 18 2.20312 18 3V13.5C18 13.9219 18.3281 14.25 18.75 14.25C19.125 14.25 19.5 13.9219 19.5 13.5V12H27V18Z" fill="white" />
         </svg>
      </div>
   )
}
