import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthContext'
import { useHistory } from 'react-router'
import Swal from 'sweetalert2'

import { ListaBusqueda } from '../ui/ListaBusqueda'
import { actualizarEnvio, buscarUsuario } from '../../services/adminRequest'
import distritos from '../../data/distritosLima'

import './adminEnvios.css'
import adduser from '../../assets/add-user.svg'
import adjunto from '../../assets/adjunto.svg'

export const AdminEnvioSingle = () => {

   const distritosLima = distritos

   const history = useHistory()

   const contentSearch = useRef(null)

   const [userValue, setUserValue] = useState("")

   const [dataBusqueda, setDataBusqueda] = useState([])

   const { data } = useContext(AuthContext)

   const { login: { token } } = data

   const { administrador: { envioActive } } = data

   const [formValues, setFormValues] = useState(
      (envioActive) ? {
         ...envioActive,
         filefactura: "",
         filecargo: "",
      } :
         {
            ...JSON.parse(localStorage.getItem("envioActive")),
            filefactura: "",
            filecargo: "",
         }
   )

   const { codigo, fregistro, estado, desde, hasta, fenvio, idusuario, destinatario, numdestinatario, apellidosusuario, nombreusuario, urlcargo, urlcomprobante, filefactura, filecargo } = formValues

   useEffect(() => {
      setUserValue(nombreusuario + " " + apellidosusuario)
   }, [nombreusuario, apellidosusuario])

   const handleChange = ({ target }) => {

      if (target.name === "filefactura" || target.name === "filecargo") {
         setFormValues({
            ...formValues,
            [target.name]: target.files[0] || ''
         })
      } else {
         setFormValues({
            ...formValues,
            [target.name]: target.value
         })

      }
   }

   const handleChangeUsuario = (ev) => {
      setUserValue(ev.target.value)
   }

   const handleSearch = (ev) => { //Event KeyUp

      if (ev.key === "Escape") {

         ev.target.value = ""

         contentSearch.current.classList.remove("activeSearch")

      } else {

         if (ev.target.value === "") {

            contentSearch.current.classList.remove("activeSearch")

         }

         else {

            buscarUsuario(ev.target.value)
               .then(respUsuarios => {

                  setDataBusqueda(respUsuarios.data)

                  contentSearch.current.classList.add("activeSearch")

               })

         }

      }

   }

   const handleDatoClick = (usuario) => {

      const codigoUser = usuario.id;
      const nombreUsuer = `${usuario.nombre} ${usuario.apellidos}`
      const correousuario = usuario.correo


      setFormValues({
         ...formValues,
         idusuario: codigoUser,
         usuariocorreo: correousuario
      })
      setUserValue(nombreUsuer)

      contentSearch.current.classList.remove("activeSearch")
   }

   const changeFile = (archivo) => {
      setFormValues((state) => ({
         ...state,
         [archivo]: ''
      }))
   }

   const handleSubmit = (ev) => {

      ev.preventDefault()

      if (formValues.estado === '') {

         Swal.fire("", "No seleccionó el estado del envio.", "warning")
         return false;
      }

      if (formValues.codigo === '') {

         Swal.fire("", "El codigo de envio esta vacio.", "warning")
         return false;
      }

      if (formValues.desde === '' || formValues.hasta === '') {

         Swal.fire("", "Seleccione los campos desde o hasta.", "warning")
         return false;
      }

      if (!(new Date(formValues.fenvio) >= new Date(formValues.fregistro))) {

         Swal.fire("", "La fecha de envio debe ser igual o mayor a la fecha de registro.", "warning")
         return false;

      }

      if (formValues.usuario === '') {

         Swal.fire("", "No coloco ningun usuario", "warning")
         return false;

      }

      if (formValues.destinatario === '' || formValues.numdestinatario === '') {

         Swal.fire("", "Los campos destinatario y/o Numero de contacto son obligatorios", "warning")
         return false;

      }

      Swal.fire({
         title: "Cargando..",
         text: "Por favor esperar",
         allowOutsideClick: false,
         didOpen: () => {
            Swal.showLoading()
         }
      })

      let formData = new FormData()

      for (const clave in formValues) {
         formData.append(clave, formValues[clave]);
      }

      actualizarEnvio(formData)
         .then(response => {

            if (!response.ok && !response.msg) {

               Swal.close()
               Swal.fire({
                  title: 'Error',
                  text: 'comuníquese con el administrador',
                  icon: 'error',
                  confirmButtonColor: '#3daf2c'
               })
               return false
            }

            Swal.close()
            Swal.fire(
               '',
               response.msg,
               'success'
            )
               .then(result => {
                  if (result.isConfirmed) {
                     localStorage.removeItem("envioActive")
                     history.replace("/admin/envios")
                  }
               })
         })



   }

   if (!envioActive && !localStorage.getItem("envioActive")) {
      return <Redirect to="/admin/envios"></Redirect>
   }

   return (

      <div className="urbex__contenido-envio ">

         <h1 className="urbex__titulo font-rubik">Envío {codigo}</h1>

         <div className="urbex__form-profile">

            <form autoComplete="off" onSubmit={handleSubmit}>

               <div className="urbex__form-group">
                  <label className="urbex__label-titulo">Estado de envío:</label>
                  <div className="urbex__inputs urbex__inputs-radio">

                     <div className="urbex__radio">
                        <input name="estado" id="tipo_procesando" checked={estado === 'procesando'} value="procesando" onChange={handleChange} type="radio" /> <label htmlFor="tipo_procesando">Procesando</label>
                     </div>

                     <div className="urbex__radio">
                        <input name="estado" id="tipo_enviando" checked={estado === 'enviando'} value="enviando" onChange={handleChange} type="radio" /> <label htmlFor="tipo_enviando">Enviando</label>
                     </div>

                     <div className="urbex__radio">
                        <input name="estado" id="tipo_entregado" checked={estado === 'entregado'} value="entregado" onChange={handleChange} type="radio" /> <label htmlFor="tipo_entregado">Entregado</label>
                     </div>

                  </div>
               </div>

               <div className="urbex__form-inputs-full">
                  <div className="urbex__input-full">
                     <label htmlFor="codigo">Codigo de envio</label>
                     <span className="inputdisabled">{codigo}</span>
                  </div>
               </div>

               <div className="urbex__form-group">
                  <div className="urbex__inputs basis">
                     <label htmlFor="desde">Desde</label>
                     <select name="desde" value={desde} onChange={handleChange} id="desde">

                        <option value="" disabled>Seleccionar</option>

                        {distritosLima.map(distritos => (
                           <option value={distritos.toLowerCase()} key={distritos}>{distritos}</option>
                        ))}

                     </select>
                  </div>
                  <div className="urbex__inputs basis">
                     <label htmlFor="hasta">Hasta</label>
                     <select name="hasta" value={hasta} onChange={handleChange} id="hasta">

                        <option value="" disabled>Seleccionar</option>

                        {distritosLima.map(distritos => (
                           <option value={distritos.toLowerCase()} key={distritos}>{distritos}</option>
                        ))}

                     </select>
                  </div>
               </div>
               <div className="urbex__form-group">

                  <div className="urbex__inputs">
                     <label htmlFor="fregistro">Fecha de registro</label>
                     <input type="date" value={fregistro} name="fregistro" onChange={handleChange} id="fregistro" />
                  </div>
                  <div className="urbex__inputs">
                     <label htmlFor="fenvio">Fecha de envío</label>
                     <input type="date" name="fenvio" value={fenvio} onChange={handleChange} id="fenvio" />
                  </div>

               </div>

               <div className="urbex__form-inputs-full">

                  <div className="urbex__input-full">
                     <label htmlFor="usuariomostrar">Usuario que envía <Link to="/admin/nuevousuario"><img src={adduser} alt="agregar" /> Añadir</Link></label>

                     <input type="text" name="usuariomostrar" value={userValue} onChange={handleChangeUsuario} onKeyUp={handleSearch} id="usuariomostrar" />
                     <input type="hidden" name="usuario" value={idusuario} onChange={handleChange}></input>
                  </div>

                  <div className="urbex__content-search" ref={contentSearch}>
                     <ListaBusqueda datosBusqueda={dataBusqueda} handleDatoOnClick={handleDatoClick}></ListaBusqueda>
                  </div>

               </div>

               <div className="urbex__form-group">
                  <div className="urbex__inputs">
                     <label htmlFor="destinatario">Destinatario</label>
                     <input type="text" name="destinatario" value={destinatario} onChange={handleChange} id="destinatario" />
                  </div>
                  <div className="urbex__inputs">
                     <label htmlFor="numdestinatario">Número de contacto</label>
                     <input type="text" name="numdestinatario" value={numdestinatario} onChange={handleChange} id="numdestinatario" />
                  </div>

               </div>

               <div className="urbex__linea-gruesa"></div>

               <div className="urbex__form-group adjunto">

                  {
                     (urlcomprobante)
                        ?
                        (
                           <div className="urbex__descargar-adjunto">
                              <a href={`${urlcomprobante}?token=${token}`} target="_blank" rel="noreferrer" download>Comprobante o factura</a>
                              <span onClick={() => {
                                 changeFile('urlcomprobante')
                              }}><i className="fas fa-upload"></i></span>
                           </div>
                        )
                        :
                        (
                           <div className="urbex__adjunto">
                              <p>Cargar comprobante o factura</p>
                              <label htmlFor="factura">
                                 <img src={adjunto} alt="" /> Adjuntar archivo
                                 <input style={{ "display": "none" }} name="filefactura" value={filefactura.filename} onChange={handleChange} id="factura" type="file" />
                              </label>
                           </div>
                        )
                  }

                  {
                     (urlcargo)
                        ?
                        (
                           <div className="urbex__descargar-adjunto">
                              <a href={`${urlcargo}?token=${token}`} target="_blank" rel="noreferrer" download>Cargo</a>
                              <span onClick={() => {
                                 changeFile('urlcargo')
                              }}><i className="fas fa-upload"></i></span>
                           </div>
                        )
                        :
                        (

                           <div className="urbex__adjunto">
                              <p>Adjuntar cargo</p>
                              <label htmlFor="cargo">
                                 <img src={adjunto} alt="" /> Adjuntar archivo
                                 <input style={{ "display": "none" }} name="filecargo" value={filecargo.filename} onChange={handleChange} id="cargo" type="file" />
                              </label>
                           </div>

                        )

                  }

               </div>

               <div className="urbex__form-group-button">
                  <button type="submit">Actualizar</button>
               </div>

            </form>

         </div>

      </div>

   )
}
