import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { AuthContext } from '../../auth/AuthContext';

import lupa from '../../assets/ver.svg'
import tacho from '../../assets/borrar.svg'
import { cantidadEnviosUsuario, eliminarUsuario } from '../../services/adminRequest';
import actionDeleteUser from '../../helpers/actionDeleteUser';

export const AdminRowData = ({ dataUsuarios }) => {

   const { setData } = useContext(AuthContext)

   const history = useHistory()

   const handleInfoUsuario = (codigoUsuario) => {

      const usuarioSeleccionado = dataUsuarios.find(user => user.codigo === codigoUsuario)

      localStorage.setItem("usuarioActive", JSON.stringify({ ...usuarioSeleccionado }))

      setData((state) => ({
         ...state,
         administrador: {
            ...state.administrador,
            usuarioActive: usuarioSeleccionado
         }
      }))

      history.push(`/admin/usuario?user=${codigoUsuario}`)

   }

   const handleEliminarUsuario = async (codigoUsuario, idusuario) => {

      const enviosPorUsuarios = await cantidadEnviosUsuario(idusuario)

      if (enviosPorUsuarios.cantidad > 0) {

         Swal.fire({
            title: 'Eliminar usuario',
            text: 'Este usuario cuenta con envíos registrados, ¿Estas seguro de eliminarlo?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: "Cancelar",
         }).then((result) => {

            if (result.isConfirmed) {

               eliminarUsuario({ codigo: codigoUsuario, tieneEnvios: enviosPorUsuarios.ok, usuarioid: idusuario })
                  .then(resp => {
                     actionDeleteUser({ setData, resp, codigoUsuario, Swal })
                  })

            }

         })
      }
      else {

         Swal.fire({
            title: 'Eliminar usuario',
            text: '¿Estas seguro de eliminarlo?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: "Cancelar",
         }).then((result) => {

            if (result.isConfirmed) {

               eliminarUsuario({ codigo: codigoUsuario, tieneEnvios: enviosPorUsuarios.ok, usuarioid: idusuario })
                  .then(resp => {
                     actionDeleteUser({ setData, resp, codigoUsuario, Swal })
                  })

            }

         })
      }
   }

   return (
      <>
         {dataUsuarios.map(item => (
            <tr className="animate__animated animate__fadeIn" key={item.codigo}>
               <td className="font-rubik">{item.codigo}</td>
               <td className="font-rubik">{item.nombre}</td>
               <td className="font-rubik">{item.apellidos}</td>
               <td className="font-rubik">{item.fecha}</td>
               <td className="font-rubik">Persona {item.tipo}</td>
               <td className="td__documento">
                  <div onClick={() => {
                     handleInfoUsuario(item.codigo)
                  }}>
                     <img src={lupa} alt="lupa" />
                  </div>
                  <div onClick={() => {
                     handleEliminarUsuario(item.codigo, item.id)
                  }}>
                     <img src={tacho} alt="tacho" />
                  </div>
               </td>
            </tr>
         ))}
      </>
   )
}

