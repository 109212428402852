import React from 'react'


export const EntregadoIcono = () => {
   return (
      <div className="urbex__icono-logo">
         <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.7188 6.64062L18.3281 1.76562C17.9531 1.01562 17.1562 0.5 16.3125 0.5H4.64062C3.79688 0.5 3 1.01562 2.625 1.76562L0.234375 6.64062C0.046875 6.92188 0 7.29688 0 7.625V18.5C0 20.1875 1.3125 21.5 3 21.5H18C19.6406 21.5 21 20.1875 21 18.5V7.625C21 7.29688 20.9062 6.92188 20.7188 6.64062ZM11.25 2H16.3125C16.5938 2 16.8281 2.1875 16.9688 2.42188L19.0312 6.5H11.25V2ZM3.98438 2.42188C4.125 2.1875 4.35938 2 4.64062 2H9.75V6.5H1.92188L3.98438 2.42188ZM19.5 18.5C19.5 19.3438 18.7969 20 18 20H3C2.15625 20 1.5 19.3438 1.5 18.5V8H19.5V18.5ZM8.71875 16.8125C8.85938 16.9531 9.04688 17 9.23438 17C9.42188 17 9.60938 16.9531 9.75 16.8125L14.7656 11.7969C15.0469 11.5156 15.0469 11.0469 14.7656 10.7656C14.4844 10.4375 13.9688 10.4375 13.6875 10.7656L9.23438 15.2188L7.26562 13.25C6.98438 12.9688 6.46875 12.9688 6.1875 13.25C5.90625 13.5312 5.90625 14 6.1875 14.2812L8.71875 16.8125Z" fill="white" />
         </svg>
      </div>
   )
}
