import React, { useState } from 'react'
import { EstadoEnvio } from '../ui/EstadoEnvio'
import { departamentos } from '../../data/departamentos'

export const ModalDetalle = ({ closeModal, envioSelect }) => {

   const desdedepartamentos = departamentos

   const [formValues] = useState(envioSelect)

   const { fregistro, fenvio, desde, hasta, destinatario, numdestinatario, estado } = formValues


   return (
      <div className="urbex__modal-detalle animate__animated animate__fadeIn">

         <div className="urbex__modal-detalle-contenido">

            <button className="urbex__close-modal detalle" onClick={() => {
               closeModal("modaldetalle")
            }}>&times;</button>

            <form>

               <div className="urbex__form-group-detalle estado-envio">

                  <p>Estado de envío:</p>

                  <EstadoEnvio estado={estado}></EstadoEnvio>


               </div>

               <div className="urbex__form-group modal">

                  <div className="urbex__inputs">
                     <label htmlFor="desde">Desde</label>
                     <select name="desde" value={desde} id="desde" disabled>
                        {desdedepartamentos.map(depa => (
                           <option value={depa.nombre_ubigeo.toLowerCase()} key={depa.id_ubigeo}>{depa.nombre_ubigeo}</option>
                        ))}
                     </select>
                  </div>

                  <div className="urbex__inputs">
                     <label htmlFor="hasta">Hasta</label>
                     <select name="hasta" value={hasta} id="hasta" disabled>
                        {desdedepartamentos.map(depa => (
                           <option value={depa.nombre_ubigeo.toLowerCase()} key={depa.id_ubigeo}>{depa.nombre_ubigeo}</option>
                        ))}
                     </select>
                  </div>

               </div>

               <div className="urbex__form-group modal">

                  <div className="urbex__inputs">
                     <label htmlFor="fregistro">Fecha de registro</label>
                     <input type="text" id="fregistro" name="fregistro" value={fregistro} disabled />
                  </div>

                  <div className="urbex__inputs">
                     <label htmlFor="fenvio">Fecha de envío</label>
                     <input type="text" name="fenvio" id="fenvio" value={fenvio} disabled />
                  </div>

               </div>

               <div className="urbex__form-group modal">

                  <div className="urbex__inputs">
                     <label htmlFor="destinatario">Destinatario</label>
                     <input type="text" name="destinatario" id="destinatario" value={destinatario} disabled />
                  </div>

                  <div className="urbex__inputs">
                     <label htmlFor="numdestinatario">Número de contacto</label>
                     <input type="text" name="numdestinatario" id="numdestinatario" value={numdestinatario} disabled />
                  </div>

               </div>

            </form>

         </div>

      </div>
   )
}
