const actionDeleteUser = ({ setData, resp, codigoUsuario, Swal }) => {

   if (resp.ok) {

      setData((state) => ({
         ...state,
         administrador: {
            ...state.administrador,
            usuarios: state.administrador.usuarios.filter(user => user.codigo !== codigoUsuario)
         }
      }))

      Swal.fire(
         'Eliminado!',
         resp.msg,
         'success'
      )

   }
   else {

      Swal.fire(
         'Error!',
         resp.msg,
         'error'
      )
   }
}

export default actionDeleteUser