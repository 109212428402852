export const sendLogin = (formValues) => {

   const baseURL = process.env.REACT_APP_API_URL

   const apiURL = `${baseURL}/auth/`

   return fetch(apiURL, {
      method: 'POST',
      headers: {
         "Content-Type": "application/json"
      },
      body: JSON.stringify(formValues)
   })
      .then(response => response.json())
      .then(resp => {
         return resp
      })

}