import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../auth/AuthContext'
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

import { NavbarAdmin } from '../components/ui/NavbarAdmin'
import { SideBarAdmin } from '../components/ui/SideBarAdmin';
import { AdminDashboarScreen } from '../components/adminDashboard/AdminDashboarScreen';
import { UsuarioInformacion } from '../components/usuarioInformacion/UsuarioInformacion';
import { NuevoUsuario } from '../components/adminNuevoUsuario/NuevoUsuario';
import { AdminEnvios } from '../components/envios/AdminEnvios';
import { AdminEnvioSingle } from '../components/envios/AdminEnvioSingle';
import { AdminNuevoEnvio } from '../components/envios/AdminNuevoEnvio';
import { getTokenRenew } from '../services/getTokenRenew';
import { Loader } from '../components/ui/Loader';

export const AdminDashboardRouter = () => {

   const { data, setData } = useContext(AuthContext)

   const { login } = data

   const history = useHistory()


   useEffect(() => {

      if (localStorage.getItem("token")) {
         getTokenRenew()
            .then(dataResp => {

               if (dataResp.ok) {

                  localStorage.setItem("token", dataResp.token)
                  localStorage.setItem("token-init-date", new Date().getTime())

                  setData((state) => ({
                     ...state,
                     login: {
                        logged: true,
                        name: dataResp.nombre,
                        codigo: dataResp.codigo,
                        rol: dataResp.tipo,
                        token: dataResp.token
                     }
                  }))

               } else {
                  history.replace("/")
               }

            })
      }
      else {
         history.replace("/")
      }



   }, [history, setData])


   if (!login.logged) {
      return (<Loader></Loader>)
   }


   return (
      <>
         <SideBarAdmin></SideBarAdmin>

         <div className="urbex__contenido">
            <NavbarAdmin></NavbarAdmin>

            <Switch>
               <Route exact path="/admin" component={AdminDashboarScreen}></Route>
               <Route exact path="/admin/usuario" component={UsuarioInformacion}></Route>
               <Route exact path="/admin/nuevousuario" component={NuevoUsuario}></Route>
               <Route exact path="/admin/envios" component={AdminEnvios}></Route>
               <Route exact path="/admin/nuevoenvio" component={AdminNuevoEnvio}></Route>
               <Route exact path="/admin/envios/:codigo" component={AdminEnvioSingle}></Route>

               <Redirect to="/admin"></Redirect>
            </Switch>

         </div>

      </>
   )
}
