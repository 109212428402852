import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../auth/AuthContext'
import { obtenerEnvios } from '../../services/usuarioRequest'
import './dashboardScreen.css'
import { PaginationTablePublic } from '../ui/PaginationTablePublic'
import { TableScreem } from './TableScreem'

export const DashboardScreen = () => {

   const [envios, setEnvios] = useState([])
   const [paginas, setPaginas] = useState(0)
   const { data: { usuario: { datosPersonales } } } = useContext(AuthContext)

   useEffect(() => {
      console.log("efecto dashboard")
      obtenerEnvios(1, datosPersonales.id)
         .then(response => {
            if (response.ok) {
               setPaginas(response.cdt)
               setEnvios(response.data)
            }
         })
   }, [datosPersonales.id])

   return (

      <div className="urbex__contenido-envio">

         <h1 className="urbex__titulo font-rubik">Mis envíos</h1>

         <TableScreem envios={envios}></TableScreem>

         <PaginationTablePublic cantidadPaginas={paginas} setEnvios={setEnvios}></PaginationTablePublic>

      </div>
   )
}
