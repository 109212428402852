import React, { useState } from 'react'
import './LoginScreen.css'
import logo from '../../assets/logo.svg'
import { Link } from 'react-router-dom'
import { recuperarPassword } from '../../services/recuperarPassword'
import Swal from 'sweetalert2'

export const RecuperarPassword = () => {

   const [contrasenaOn, setContrasenaOn] = useState(false)

   const handleSubmit = async (ev) => {

      ev.preventDefault()

      Swal.fire({
         title: "Cargando..",
         text: "Por favor esperar",
         allowOutsideClick: false,
         didOpen: () => {
            Swal.showLoading()
         }
      })

      let response = await recuperarPassword(ev.target.correo.value)

      console.log(response)

      if (response.ok) {
         Swal.close()
         setContrasenaOn(true)
      }
      else {

         Swal.close()

         Swal.fire({
            title: 'Error',
            text: response.msg,
            icon: 'error',
            confirmButtonColor: '#3daf2c'
         })
      }

   }

   return (
      <div className="login__contenedor">

         <div className="login__logo">
            <img src={logo} alt="logo" />
         </div>

         <div className="login__contenido-formulario">

            <h2 className="login__titulo font-rubik">Recuperar contraseña</h2>

            {contrasenaOn ? (
               <p class="texto-envio-correo-contrasena"><strong>Se envió a tu correo el link para restaurar tu <br /> contraseña</strong></p>
            ) : (
               <>
                  <p className="login__subtitulo font-rubik">Ingresa tu correo para recuperar tu contraseña</p>

                  <form className="login__tag-formulario" autoComplete="off" onSubmit={handleSubmit}>

                     <div className="login__formulario-group">
                        <label htmlFor="correo" className="font-rubik">Correo</label>
                        <input type="text" name="correo" id="correo" className="font-rubik" required />
                     </div>

                     {/* <div className="login__formulario-group">
                        <label htmlFor="contrasena" className="font-rubik">Correo</label>
                        <input type="password" name="contrasena" onChange={hadleChange} value={contrasena} className="font-rubik" required />
                     </div> */}

                     <div className="login__formulario-group login__formulario-group--buttons">
                        <button
                           type="submit"
                           className="font-rubik"
                        >Recuperar</button>
                        <Link className="login__formulario-olvidastes-contrasena font-rubik" to="/">Ir a login</Link>
                     </div>

                  </form>
               </>
            )}


         </div>

         <div className="login__pie-pagina">
            <p className="font-rubik">¿No tienes una cuenta? <a href="/">Solicítala aquí</a></p>
         </div>

      </div>
   )
}
