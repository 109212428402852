import React, { useContext, useState } from 'react'
import { createPortal } from 'react-dom'

import { RowData } from './RowData'

import { ModalDetalle } from './ModalDetalle'
import { AuthContext } from '../../auth/AuthContext'
import Swal from 'sweetalert2'

export const TableScreem = ({ envios }) => {

   const { data: { login } } = useContext(AuthContext)

   const [modal, setModal] = useState(false)
   const [modalDetalle, setModalDetalle] = useState(false)

   const [envioSelect, setEnvioSelect] = useState({})

   const [urls, setUrls] = useState('')

   const openModal = (tipoModal, url) => {

      if (tipoModal === "modalpdf") {

         if (url) {
            setUrls(url);
            setModal(true);
         }
         else {

            Swal.fire({
               title: "",
               text: "No hay documento",
               icon: "info",
               confirmButtonColor: '#3daf2c'
            });
            return false;
         }



      } else {
         setModalDetalle(true);

      }




   }

   const closeModal = (tipoModal) => {

      if (tipoModal === "modalpdf") {
         setModal(false)

      } else {
         setModalDetalle(false)
      }
   }

   const obtenerEnvio = (envioID) => {
      let envioSeleccionado = envios.find(envio => envio.codigo === envioID)
      setEnvioSelect({ ...envioSeleccionado })
   }


   return (
      <>
         {
            modal && createPortal(
               <div className="urbex__modal-pdf animate__animated animate__fadeIn">
                  <div className="urbex__iframe-pdf">
                     <button className="urbex__close-modal pdf" onClick={() => {
                        closeModal("modalpdf")
                     }}>&times;</button>
                     <iframe src={urls + `?token=${login.token}`} frameBorder="0" title="modalpdf"></iframe>


                  </div>
               </div>,
               document.getElementById("portal")
            )
         }

         {
            modalDetalle && createPortal(
               <ModalDetalle closeModal={closeModal} envioSelect={envioSelect}></ModalDetalle>,
               document.getElementById("portal")
            )


         }

         <div className="urbex__table-envio">
            <table>
               <thead>
                  <tr>
                     <th>Código</th>
                     <th>Desde</th>
                     <th>Hasta</th>
                     <th>Fecha</th>
                     <th>Documento</th>
                     <th>Detalle</th>
                  </tr>
               </thead>
               <tbody>
                  <RowData data={envios} openModal={openModal} obtenerEnvio={obtenerEnvio}></RowData>
               </tbody>

            </table>
         </div>
      </>
   )
}
