export const obtenerUsuarios = (pag = 1) => {

   const baseURL = process.env.REACT_APP_API_URL

   const apiURL = `${baseURL}/auth/obtenerusuarios?pag=${pag}`

   const token = localStorage.getItem("token") || ''

   return fetch(apiURL, {
      headers: {
         "x-token": token
      }
   })
      .then(response => response.json())
      .then(resp => {

         return resp
      })
}

export const registrarUsuario = (body) => {

   const baseURL = process.env.REACT_APP_API_URL

   const apiURL = `${baseURL}/auth/new`

   const token = localStorage.getItem("token") || ''

   return fetch(apiURL, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
         "Content-Type": "application/json",
         "x-token": token
      }
   })
      .then(response => response.json())
      .then(resp => {

         return resp
      })

}

export const actualizarUsuario = (body) => {

   const baseURL = process.env.REACT_APP_API_URL

   const apiURL = `${baseURL}/auth/actualizarusuario`

   const token = localStorage.getItem("token") || ''

   return fetch(apiURL, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
         "Content-Type": "application/json",
         "x-token": token
      }
   })
      .then(response => response.json())
      .then(resp => {

         return resp
      })

}

export const cantidadEnviosUsuario = (usuarioid) => {

   const baseURL = process.env.REACT_APP_API_URL

   const apiURL = `${baseURL}/auth/cantidadenviosusuario/${usuarioid}`

   const token = localStorage.getItem("token") || ''

   return fetch(apiURL, {
      method: "POST",
      headers: {
         "x-token": token
      }
   })
      .then((response) => response.json())
      .then(resp => resp)
}

export const eliminarUsuario = (body) => {

   const baseURL = process.env.REACT_APP_API_URL

   const apiURL = `${baseURL}/auth/deleteuser`

   const token = localStorage.getItem("token") || ''

   console.log(body)

   return fetch(apiURL, {
      method: "POST",
      headers: {
         "x-token": token,
         "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
   })
      .then((response) => response.json())
      .then(resp => resp)

}

export const buscarUsuario = (nombre) => {

   const baseURL = process.env.REACT_APP_API_URL

   const apiURL = `${baseURL}/auth/buscarusuario/${nombre}`

   const token = localStorage.getItem("token") || ''

   return fetch(apiURL, {
      headers: {
         "x-token": token
      }
   })
      .then((response) => response.json())
      .then(resp => resp)

}

export const buscarUsuariosParaTabla = (nombre) => {

   const baseURL = process.env.REACT_APP_API_URL

   const apiURL = `${baseURL}/auth/buscarusuarioparatabla/${nombre}`

   const token = localStorage.getItem("token") || ''

   return fetch(apiURL, {
      headers: {
         "x-token": token
      }
   })
      .then((response) => response.json())
      .then(resp => resp)

}


// ==================== ENVIOS ====================

export const obtenerEnvios = (pag = 1) => {

   const baseURL = process.env.REACT_APP_API_URL

   const apiURL = `${baseURL}/envios/mostrarenvios?pag=${pag}`

   const token = localStorage.getItem("token") || ''

   return fetch(apiURL, {
      headers: {
         "x-token": token
      }
   })
      .then(response => response.json())
      .then(resp => {

         return resp
      })
}

export const guardarEnvio = (envio) => {

   const baseURL = process.env.REACT_APP_API_URL

   const apiURL = `${baseURL}/envios/guardarenvio`

   const token = localStorage.getItem("token") || ''

   return fetch(apiURL, {
      method: 'POST',
      body: envio,
      headers: {
         "x-token": token
      }
   })
      .then((response) => response.json())
      .then(resp => resp)
}

export const actualizarEnvio = (envio) => {

   const baseURL = process.env.REACT_APP_API_URL

   const apiURL = `${baseURL}/envios/actualizarenvio`

   const token = localStorage.getItem("token") || ''

   return fetch(apiURL, {
      method: 'PUT',
      body: envio,
      headers: {
         "x-token": token
      }
   })
      .then((response) => response.json())
      .then(resp => resp)
}

export const eliminarEnvio = (envio) => {

   const baseURL = process.env.REACT_APP_API_URL

   const apiURL = `${baseURL}/envios/eliminarenvio/${envio}`

   const token = localStorage.getItem("token") || ''

   return fetch(apiURL, {
      method: 'DELETE',
      body: envio,
      headers: {
         "x-token": token
      }
   })
      .then((response) => response.json())
      .then(resp => resp)
      .catch(error => error)
}

export const buscarEnviosParaTabla = (correo) => {

   const baseURL = process.env.REACT_APP_API_URL

   const apiURL = `${baseURL}/envios/buscarenvioparatabla/${correo}`

   const token = localStorage.getItem("token") || ''

   return fetch(apiURL, {
      headers: {
         "x-token": token
      }
   })
      .then((response) => response.json())
      .then(resp => resp)

}




