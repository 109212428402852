import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../auth/AuthContext'
import { Switch, Route, useHistory } from "react-router-dom";

import { DashboardScreen } from '../components/dashboard/DashboardScreen';
import { DatosPersonales } from '../components/datospersonales/DatosPersonales';
import { NavBar } from '../components/ui/NavBar'
import { SideBar } from '../components/ui/SideBar';
import { getTokenRenew } from '../services/getTokenRenew';
import { Loader } from '../components/ui/Loader';

export const DashboardRouter = () => {

   const { data, setData } = useContext(AuthContext)

   const { login } = data

   const history = useHistory()


   useEffect(() => {

      if (localStorage.getItem("token")) {
         getTokenRenew()
            .then(dataResp => {

               if (dataResp.ok) {

                  localStorage.setItem("token", dataResp.token)
                  localStorage.setItem("token-init-date", new Date().getTime())

                  const usuarioactive = JSON.parse(localStorage.getItem("usuarioactive")) || undefined

                  setData((state) => ({
                     ...state,
                     login: {
                        logged: true,
                        name: dataResp.nombre,
                        codigo: dataResp.codigo,
                        rol: dataResp.tipo,
                        token: dataResp.token
                     },
                     usuario: { datosPersonales: usuarioactive }
                  }))

               } else {
                  history.replace("/")
               }

            })
      }
      else {
         history.replace("/")
      }



   }, [history, setData])

   if (!login.logged || login.rol === "administrador") {

      return (
         <Loader></Loader>
      )
   }


   return (
      <>
         <SideBar></SideBar>

         <div className="urbex__contenido">
            <NavBar></NavBar>

            <Switch>
               <Route exact path="/panel" component={DashboardScreen}></Route>
               <Route exact path="/panel/datos-personales" component={DatosPersonales}></Route>
            </Switch>

         </div>

      </>
   )
}
