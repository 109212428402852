import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import camionsito from '../../assets/camionsito.svg'
import { AuthContext } from '../../auth/AuthContext'
import { buscarEnviosParaTabla, obtenerEnvios } from '../../services/adminRequest'
import { PaginationTable } from '../dashboard/PaginationTable'
import { AdminTableEnvios } from './AdminTableEnvios'

export const AdminEnvios = () => {

   const [buscarEnvio, setBuscarEnvio] = useState('')

   const [cantidadEnvios, setCantidadEnvios] = useState(0)

   const [dataBuscarEnvios, setDataBuscarEnvios] = useState(undefined)

   const { setData } = useContext(AuthContext)

   const handleKeyUp = (ev) => {

      if (ev.code === "Escape") {
         setBuscarEnvio('')
         setDataBuscarEnvios(undefined)

         obtenerEnvios()
            .then(response => {

               if (response.ok) {

                  console.log(response.data)

                  setCantidadEnvios(response.cdt)

                  setData((store) => ({
                     ...store,
                     administrador: {
                        ...store.administrador,
                        envios: response.data
                     }
                  }))

               }
            })

         return false
      }

      buscarEnviosParaTabla(buscarEnvio)
         .then(response => {

            if (response.ok) {

               if (response.data.length > 0) {

                  console.log(response.data)

                  let cantidadPaginas = Math.ceil((response.data.length) / 5)

                  let arrayEnvios = []

                  for (let i = 0; i < cantidadPaginas; i++) {
                     arrayEnvios.push(response.data.slice((i * 5), ((i + 1) * 5)))
                  }

                  setDataBuscarEnvios(arrayEnvios)

                  if (dataBuscarEnvios?.length > 0) {

                     setData((state) => ({
                        ...state,
                        administrador: {
                           ...state.administrador,
                           envios: dataBuscarEnvios[0]
                        }
                     }))
                  }
               }
            }
         })

   }

   const handleChange = (ev) => {
      setBuscarEnvio(ev.target.value)
   }

   const handleClickPage = (pag) => {

      setData((state) => ({
         ...state,
         administrador: {
            ...state.administrador,
            envios: dataBuscarEnvios[pag]
         }
      }))

   }

   return (
      <div className="urbex__contenido-envio">

         <div className="urbex__contenido-titulo-info">

            <h1 className="urbex__titulo font-rubik">Envíos</h1>

            <div className="urbex__info">
               <input
                  type="text"
                  className="urbex__usuarios-buscador"
                  placeholder="Buscar envíos..."
                  onKeyUp={handleKeyUp}
                  value={buscarEnvio}
                  onChange={handleChange}
               />
               <Link to="/admin/nuevoenvio" className="urbex__link-agregar-usuarios"><img src={camionsito} alt="adduser" /> <span>Agregar envío</span></Link>
            </div>

         </div>

         <AdminTableEnvios setCantidadEnvios={setCantidadEnvios}></AdminTableEnvios>

         {(buscarEnvio) ? '' : (<PaginationTable tipo="envios" cantidadPaginas={cantidadEnvios}></PaginationTable>)}

         {dataBuscarEnvios &&
            (
               <div className="urbex__paginacion">
                  <ul>
                     {Array.from(Array(dataBuscarEnvios.length)).map((_, i) => (
                        <li
                           key={i}
                           onClick={() => handleClickPage(i)}
                        >{i + 1}</li>
                     ))}
                  </ul>
               </div>
            )
         }

      </div>
   )
}
