import React, { useState } from 'react'
import { useHistory } from 'react-router'
import Swal from 'sweetalert2'
import { registrarUsuario } from '../../services/adminRequest'

export const NuevoUsuario = () => {

   const history = useHistory()

   const [tipoUsurio, setTipoUsurio] = useState(false)

   const [formValues, setFormValues] = useState({
      codigo: '',
      nombre: '',
      apellidos: '',
      tipo: '',
      ruc: '',
      razon_social: '',
      tipodoc: '',
      documento: '',
      telefono: '',
      correo: '',
      contrasena: '',
      contrasena2: ''

   })

   const { tipo, codigo, nombre, apellidos, ruc, razon_social, tipodoc, documento, telefono, correo, contrasena, contrasena2 } = formValues

   const handleFormChange = ({ target }) => {
      setFormValues({
         ...formValues,
         [target.name]: target.value
      })
   }

   const mostrarTipoUsuario = () => {
      setTipoUsurio(true)
   }

   const ocultarTipoUsuario = () => {
      setTipoUsurio(false)
   }

   const handleSubmit = (ev) => {

      ev.preventDefault();

      if (!tipo) {

         Swal.fire("", "Escoja el tipo de usuario.", "error")
         return false;
      }

      if (!codigo) {

         Swal.fire("", "El codigo esta vacio.", "error")
         return false;
      }

      if (!nombre) {

         Swal.fire("", "Escriba el nombre.", "error")
         return false;
      }

      if (!apellidos) {

         Swal.fire("", "Escriba los apellidos.", "error")
         return false;
      }

      if (!tipodoc) {

         Swal.fire("", "Especifique el tipo de documento.", "error")
         return false;
      }

      if (!documento) {

         Swal.fire("", "Escriba el documento.", "error")
         return false;
      }

      if (!telefono) {

         Swal.fire("", "Escriba el telefono.", "error")
         return false;
      }

      if (!correo) {

         Swal.fire("", "Escriba el correo.", "error")
         return false;
      }

      if (!contrasena) {

         Swal.fire("", "Escriba una contraseña.", "error")
         return false;
      }

      if (contrasena !== contrasena2) {

         Swal.fire({
            title: 'Error',
            text: 'Las contraseñas no coinciden.',
            icon: 'error',
            confirmButtonColor: '#3daf2c'
         })

         return false;
      }

      Swal.fire({
         title: "Cargando..",
         text: "Por favor esperar",
         allowOutsideClick: false,
         didOpen: () => {
            Swal.showLoading()
         }


      })

      registrarUsuario(formValues)
         .then(response => {
            if (response.ok) {
               Swal.close()
               Swal.fire({
                  title: "",
                  text: response.msg,
                  icon: "success",
                  confirmButtonColor: '#3daf2c',
                  cancelButtonText: "Cancelar",
               })
                  .then(result => {
                     if (result.isConfirmed) {
                        history.replace("/admin")
                     }
                  })
            } else if (!response.ok && !response.msg) {

               if (response.errors.contrasena || response.errors.correo) {
                  Swal.close()
                  Swal.fire({
                     title: 'Error',
                     text: (response.errors.contrasena) ? response.errors.contrasena.msg : response.errors.correo.msg,
                     icon: 'error',
                     confirmButtonColor: '#3daf2c'
                  })

                  return false
               }
            }
            else {
               Swal.close()
               Swal.fire("Error", response.msg, "error")
               return false;

            }
         })

   }

   return (
      <div className="urbex__contenido-envio">
         <h1 className="urbex__titulo font-rubik">Nuevo Usuario</h1>

         <div className="urbex__form-profile">

            <form onSubmit={handleSubmit}>

               <div className="urbex__form-group">
                  <label className="urbex__label-titulo">Tipo de usuario</label>
                  <div className="urbex__inputs urbex__inputs-radio">

                     <div className="urbex__radio">
                        <input name="tipo" id="tipo_usuario_natural" value="natural" checked={tipo === "natural"} onChange={handleFormChange} onClick={ocultarTipoUsuario} type="radio" /> <label htmlFor="tipo_usuario_natural">Persona Natural</label>
                     </div>
                     <div className="urbex__radio">
                        <input name="tipo" id="tipo_usuario_empresa" value="empresa" checked={tipo === "empresa"} onChange={handleFormChange} onClick={mostrarTipoUsuario} type="radio" /> <label htmlFor="tipo_usuario_empresa">Empresa</label>
                     </div>

                  </div>
               </div>

               <div className="urbex__form-inputs-full">
                  <div className="urbex__input-full">
                     <label htmlFor="codigo">Codigo de usuario</label>
                     <input type="text" value={codigo} onChange={handleFormChange} name="codigo" id="codigo" />
                  </div>
               </div>

               {
                  tipoUsurio &&
                  <div className="urbex__form-group">
                     <div className="urbex__inputs">
                        <label htmlFor="ruc">RUC</label>
                        <input type="text" name="ruc" value={ruc} onChange={handleFormChange} id="ruc" />
                     </div>
                     <div className="urbex__inputs">
                        <label htmlFor="razsocial">Razon Social</label>
                        <input type="text" name="razon_social" value={razon_social} onChange={handleFormChange} id="razsocial" />
                     </div>
                  </div>
               }

               <div className="urbex__form-group">
                  <div className="urbex__inputs">
                     <label htmlFor="nombres">Nombres</label>
                     <input type="text" value={nombre} onChange={handleFormChange} name="nombre" id="nombres" />
                  </div>
                  <div className="urbex__inputs">
                     <label htmlFor="apellido">Apellidos</label>
                     <input type="text" value={apellidos} onChange={handleFormChange} name="apellidos" id="apellido" />
                  </div>
               </div>
               <div className="urbex__form-group">
                  <div className="urbex__inputs">
                     <label htmlFor="tipodoc">Tipo de Documento</label>
                     <select name="tipodoc" value={tipodoc} onChange={handleFormChange} id="tipodoc" required>
                        <option value="" disabled>Tipo de documento</option>
                        <option value="dni">DNI</option>
                        <option value="carnet-extranjeria">Carnet De Extranjeria</option>
                        <option value="reg-unico-contribuyente">Reg. Unico De Contribuyentes</option>
                        <option value="pasaporte">Pasaporte</option>
                        <option value="otros">Otros</option>
                     </select>
                  </div>
                  <div className="urbex__inputs">
                     <label htmlFor="documento">Nro. de Documento</label>
                     <input type="text" name="documento" value={documento} onChange={handleFormChange} id="documento" required />
                  </div>

               </div>
               <div className="urbex__form-group">
                  <div className="urbex__inputs">
                     <label htmlFor="telefono">Teléfono</label>
                     <input type="text" name="telefono" value={telefono} onChange={handleFormChange} id="telefono" />
                  </div>
                  <div className="urbex__inputs">
                     <label htmlFor="correo">Correo Electrónico</label>
                     <input type="text" name="correo" value={correo} onChange={handleFormChange} id="correo" required />
                  </div>

               </div>

               <div className="urbex__linea-gruesa"></div>

               <div className="urbex__form-group">

                  <div className="urbex__inputs">
                     <label htmlFor="contrasena">Contraseña</label>
                     <input type="password" name="contrasena" value={contrasena} onChange={handleFormChange} id="contrasena" required />
                  </div>
                  <div className="urbex__inputs">
                     <label htmlFor="contrasena2">Vuelve a escribir la Contraseña</label>
                     <input type="password" name="contrasena2" value={contrasena2} onChange={handleFormChange} id="contrasena2" required />
                  </div>

               </div>

               <div className="urbex__form-group-button">
                  <button type="submit">Guardar</button>
               </div>

            </form>

         </div>

      </div>
   )
}
