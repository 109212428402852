import React, { useContext, useState } from 'react'
import Swal from 'sweetalert2'
import { AuthContext } from '../../auth/AuthContext'
import { actualizarDatosPersonales } from '../../services/usuarioRequest'
import { useHistory } from 'react-router-dom';

import './datosPersonales.css'

export const DatosPersonales = () => {

   const history = useHistory()

   const { data: { usuario }, setData } = useContext(AuthContext)

   const [formValues, setFormValues] = useState({
      ...usuario.datosPersonales,
      contrasena: '',
      nuevacontrasena: ''
   })

   const { nombre, apellidos, tipodoc, documento, telefono, correo, contrasena, nuevacontrasena } = formValues

   const handleChange = (ev) => {
      setFormValues({
         ...formValues,
         [ev.target.name]: ev.target.value
      })
   }


   const handleSubmit = async (e) => {
      e.preventDefault()

      Swal.fire({
         title: "Cargando..",
         text: "Por favor esperar",
         allowOutsideClick: false,
         didOpen: () => {
            Swal.showLoading()
         }


      })

      if (nombre === "") {
         Swal.close()
         Swal.fire({
            title: "",
            text: "El nombre esta vacio.",
            icon: "warning",
            confirmButtonColor: '#3daf2c'

         })
         return false;

      }
      if (apellidos === "") {
         Swal.close()
         Swal.fire({
            title: "",
            text: "El apellido esta vacio.",
            icon: "warning",
            confirmButtonColor: '#3daf2c'

         })
         return false;

      }
      if (tipodoc === "") {
         Swal.close()
         Swal.fire({
            title: "",
            text: "Especifique su tipo de documento.",
            icon: "warning",
            confirmButtonColor: '#3daf2c'

         })
         return false;

      }
      if (documento === "") {
         Swal.close()
         Swal.fire({
            title: "",
            text: "Su documento esta vacio.",
            icon: "warning",
            confirmButtonColor: '#3daf2c'

         })
         return false;

      }
      if (telefono === "") {
         Swal.close()
         Swal.fire({
            title: "",
            text: "Escriba un telefono.",
            icon: "warning",
            confirmButtonColor: '#3daf2c'

         })
         return false;

      }
      if (correo === "") {
         Swal.close()
         Swal.fire({
            title: "",
            text: "Escriba un correo.",
            icon: "warning",
            confirmButtonColor: '#3daf2c'

         })
         return false;

      }
      if (contrasena !== nuevacontrasena) {
         Swal.close()
         Swal.fire({
            title: "",
            text: "Las contraseñas no coinciden",
            icon: "warning",
            confirmButtonColor: '#3daf2c'

         })
         return false;
      }



      let response = await actualizarDatosPersonales(formValues)

      if (response.ok) {
         Swal.close()
         Swal.fire({
            title: "",
            text: response.msg,
            icon: "success",
            confirmButtonColor: '#3daf2c',
            cancelButtonText: "Cancelar",

         })
            .then(resp => {
               if (resp.isConfirmed) {

                  setData((state) => ({
                     ...state,
                     usuario: {
                        ...state.usuario,
                        datosPersonales: { ...formValues }
                     }

                  }))

                  localStorage.setItem("usuarioactive", JSON.stringify(formValues))

                  history.push("/panel")
               }
            })


      } else {
         Swal.close()
         Swal.fire("Error", response.msg, "error")

      }
   }

   return (
      <div className="urbex__contenido-datos-personales animate__animated animate__fadeIn">

         <h1 className="urbex__titulo font-rubik">Mis datos personales</h1>

         <div className="urbex__form-profile">

            <form onSubmit={handleSubmit}>

               <div className="urbex__form-group">
                  <div className="urbex__inputs">
                     <label htmlFor="nombres">Nombres</label>
                     <input type="text" name="nombre" value={nombre} onChange={handleChange} id="nombre" />
                  </div>
                  <div className="urbex__inputs">
                     <label htmlFor="apellido">Apellidos</label>
                     <input type="text" name="apellidos" value={apellidos} onChange={handleChange} id="apellidos" />
                  </div>
               </div>
               <div className="urbex__form-group">
                  <div className="urbex__inputs">
                     <label htmlFor="tipodocumento">Tipo de Documento</label>
                     {/* <input type="text" name="tipodoc" value={tipodoc} onChange={handleChange} id="tipodoc" /> */}
                     <select name="tipodoc" value={tipodoc} onChange={handleChange} id="tipodoc" required>
                        <option value="" disabled>Tipo de documento</option>
                        <option value="dni">DNI</option>
                        <option value="carnet-extranjeria">Carnet De Extranjeria</option>
                        <option value="reg-unico-contribuyente">Reg. Unico De Contribuyentes</option>
                        <option value="pasaporte">Pasaporte</option>
                        <option value="otros">Otros</option>
                     </select>
                  </div>
                  <div className="urbex__inputs">
                     <label htmlFor="documento">Nro. de Documento</label>
                     <input type="text" name="documento" value={documento} onChange={handleChange} id="documento" />
                  </div>

               </div>
               <div className="urbex__form-group">
                  <div className="urbex__inputs">
                     <label htmlFor="telefono">Teléfono</label>
                     <input type="text" name="telefono" value={telefono} onChange={handleChange} id="telefono" />
                  </div>
                  <div className="urbex__inputs">
                     <label htmlFor="correo">Correo Electrónico</label>
                     <input type="text" name="correo" value={correo} onChange={handleChange} id="correo" />
                  </div>

               </div>

               <div className="urbex__linea-gruesa"></div>

               <div className="urbex__form-group">

                  <div className="urbex__inputs">
                     <label htmlFor="contrasena">Contraseña</label>
                     <input type="password" name="contrasena" value={contrasena} onChange={handleChange} id="contrasena" />
                  </div>
                  <div className="urbex__inputs">
                     <label htmlFor="nuevacontrasena">Nueva Contraseña</label>
                     <input type="password" name="nuevacontrasena" value={nuevacontrasena} onChange={handleChange} id="nuevacontrasena" />
                  </div>

               </div>

               <div className="urbex__form-group-button">
                  <button type="submit">Actualizar</button>
               </div>

            </form>

         </div>

      </div>
   )
}
