import React from 'react'

export const RowData = ({ data, openModal, obtenerEnvio }) => {
   return (
      <>
         {data.map(item => (
            <tr className="animate__animated animate__fadeIn" key={item.codigo}>
               <td className="font-rubik">{item.codigo}</td>
               <td className="font-rubik">{item.desde}</td>
               <td className="font-rubik">{item.hasta}</td>
               <td className="font-rubik">{item.fecha}</td>
               <td className="td__documento">
                  <div onClick={
                     () => { openModal("modalpdf", item.urlcomprobante) }
                  }>
                     <span><i className="fas fa-file-download"></i></span>
                     <span>Comprobante</span>
                  </div>
                  <div onClick={
                     () => { openModal("modalpdf", item.urlcargo) }
                  }>
                     <span><i className="fas fa-file-download"></i></span>
                     <span>Cargo</span>
                  </div>
               </td>
               <td className="td__detalle"><span className="search" onClick={() => {
                  obtenerEnvio(`${item.codigo}`)
                  openModal("modaldetalle", '')
               }}><i className="fas fa-search"></i></span></td>
            </tr>
         ))}
      </>
   )
}

