import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import './LoginScreen.css'
import logo from '../../assets/logo.svg'
import { Loader } from '../ui/Loader'
import { cambiarContrasena, validarToken } from '../../services/recuperarPassword'
import Swal from 'sweetalert2'

export const CambiarContrasena = ({ location }) => {

   const history = useHistory()

   const querysURL = new URLSearchParams(location.search)

   const token = querysURL.get("token")

   const [showView, setShowView] = useState(false)

   useEffect(() => {

      validarToken(token)
         .then(response => {
            if (response.ok) {
               setShowView(true)
            }
            else {

               Swal.fire({
                  title: 'Error',
                  text: response.msg,
                  icon: 'error',
                  confirmButtonColor: '#3daf2c',
                  cancelButtonText: "Cancelar",
               })
                  .then(resp => {
                     if (resp.isConfirmed) {
                        history.replace("/")
                     }
                  })
            }

         })
         .catch(err => {
            console.log(err)
            Swal.fire({
               title: 'Error',
               text: "Contactese con el administrador",
               icon: 'error',
               confirmButtonColor: '#3daf2c',
               cancelButtonText: "Cancelar",
            })
               .then(resp => {
                  if (resp.isConfirmed) {
                     history.replace("/")
                  }
               })
         })

   }, [token])

   if (!showView) {
      return (
         <Loader></Loader>
      )
   }

   const handleSubmit = (ev) => {

      ev.preventDefault()

      Swal.fire({
         title: "Cargando..",
         text: "Por favor esperar",
         allowOutsideClick: false,
         didOpen: () => {
            Swal.showLoading()
         }

      })

      const contrasena = ev.target.contrasena.value
      const contrasena2 = ev.target.contrasena2.value

      if (contrasena !== contrasena2) {
         Swal.fire("Error", "Las contraseñas no coinciden", "error")
      }
      else {
         cambiarContrasena({ contrasena, contrasena2, token })
            .then(response => {
               Swal.close()

               if (response.ok) {
                  Swal.fire({
                     title: "",
                     text: response.msg,
                     icon: "success",
                     confirmButtonColor: '#3daf2c',
                     cancelButtonText: "Cancelar",

                  })
                     .then(resp => {
                        if (resp.isConfirmed) {
                           history.replace("/")
                        }
                     })

               } else {
                  Swal.fire("Error", response.msg, "error")
               }
            })
      }


   }

   return (
      <div className="login__contenedor">

         {/* <h1>Recuperar contrasena</h1> */}

         <div className="login__logo">
            <img src={logo} alt="logo" />
         </div>

         <div className="login__contenido-formulario">

            <h2 className="login__titulo font-rubik">Nueva contraseña</h2>
            <p className="login__subtitulo font-rubik">Emplea una contraseña segura y que puedas recordar</p>

            <form className="login__tag-formulario" autoComplete="off" onSubmit={handleSubmit}>

               <div className="login__formulario-group">
                  <label htmlFor="correo" className="font-rubik">Ingresa contraseña</label>
                  <input type="password" name="contrasena" id="contrasena" className="font-rubik" required />
               </div>

               <div className="login__formulario-group">
                  <label htmlFor="contrasena" className="font-rubik">Vuelve a ingresar la contraseña</label>
                  <input type="password" name="contrasena2" className="font-rubik" required />
               </div>

               <div className="login__formulario-group login__formulario-group--buttons">
                  <button
                     type="submit"
                     className="font-rubik"
                  >Guardar</button>
                  {/* <Link className="login__formulario-olvidastes-contrasena font-rubik" to="/">¿Olvidaste tu contraseña?</Link> */}
               </div>

            </form>

         </div>

         <div className="login__pie-pagina">
            <p className="font-rubik">¿No tienes una cuenta? <a href="/">Solicítala aquí</a></p>
         </div>

      </div>
   )
}
