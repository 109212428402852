import React from 'react'

export const ProcesadoIcono = () => {
   return (
      <div className="urbex__icono-logo">
         <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.5 15H4.5C2.01562 15 0 17.0156 0 19.5C0 21.9844 2.01562 24 4.5 24H25.5C27.9844 24 30 21.9844 30 19.5C30 17.0156 27.9844 15 25.5 15ZM25.5 22.5H4.5C2.8125 22.5 1.5 21.1875 1.5 19.5C1.5 17.8594 2.8125 16.5 4.5 16.5H25.5C27.1406 16.5 28.5 17.8594 28.5 19.5C28.5 21.1875 27.1406 22.5 25.5 22.5ZM9 13.5H21C22.6406 13.5 24 12.1875 24 10.5V3C24 1.35938 22.6406 0 21 0H9C7.3125 0 6 1.35938 6 3V10.5C6 12.1875 7.3125 13.5 9 13.5ZM12.75 1.5H17.25V4.82812L15.3281 3.84375C15.0938 3.75 14.8594 3.75 14.625 3.84375L12.75 4.82812V1.5ZM7.5 3C7.5 2.20312 8.15625 1.5 9 1.5H11.25V6C11.25 6.28125 11.3438 6.51562 11.5781 6.65625C11.8125 6.79688 12.0938 6.79688 12.3281 6.70312L15 5.34375L17.625 6.70312C17.7656 6.75 17.8594 6.75 18 6.75C18.0938 6.75 18.2344 6.75 18.375 6.65625C18.6094 6.51562 18.75 6.28125 18.75 6V1.5H21C21.7969 1.5 22.5 2.20312 22.5 3V10.5C22.5 11.3438 21.7969 12 21 12H9C8.15625 12 7.5 11.3438 7.5 10.5V3ZM6 18.375C5.34375 18.375 4.875 18.8906 4.875 19.5C4.875 20.1562 5.34375 20.625 6 20.625C6.60938 20.625 7.125 20.1562 7.125 19.5C7.125 18.8906 6.60938 18.375 6 18.375ZM15 18.375C14.3438 18.375 13.875 18.8906 13.875 19.5C13.875 20.1562 14.3438 20.625 15 20.625C15.6094 20.625 16.125 20.1562 16.125 19.5C16.125 18.8906 15.6094 18.375 15 18.375ZM24 18.375C23.3438 18.375 22.875 18.8906 22.875 19.5C22.875 20.1562 23.3438 20.625 24 20.625C24.6094 20.625 25.125 20.1562 25.125 19.5C25.125 18.8906 24.6094 18.375 24 18.375Z" fill="white" />
         </svg>

      </div>
   )
}
