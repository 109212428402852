import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../../auth/AuthContext'
import { obtenerEnvios } from '../../services/adminRequest'
import { AdminRowEnvio } from './AdminRowEnvio'

export const AdminTableEnvios = ({ setCantidadEnvios }) => {

   const { data, setData } = useContext(AuthContext)

   const { administrador: { envios } } = data

   useEffect(() => {

      obtenerEnvios()
         .then(response => {

            if (response.ok) {

               setCantidadEnvios(response.cdt)

               setData((store) => ({
                  ...store,
                  administrador: {
                     ...store.administrador,
                     envios: response.data
                  }
               }))

            }
         })

   }, [setData, setCantidadEnvios])

   return (
      <div className="urbex__table-envio">
         <table>
            <thead>
               <tr>
                  <th>Código</th>
                  <th>Desde</th>
                  <th>Hasta</th>
                  <th>Fecha</th>
                  <th>Email Usuario</th>
                  <th>Opciones</th>
               </tr>
            </thead>
            <tbody>

               <AdminRowEnvio dataEnvios={envios}></AdminRowEnvio>

            </tbody>

         </table>
      </div>
   )
}
