// import logo from './assets/logo.svg';
import { useState } from 'react';
import { AuthContext } from './auth/AuthContext';
import { UiContext } from './components/ui/UiContext';
import { AppRouter } from './routers/AppRouter';

// aca crearemos para poder obtener o inicializar si un usuario ya se autentico anteriormente

function UrBexApp() {

  const [data, setData] = useState({
    login: {
      logged: false,
    },
    administrador: {
      usuarios: [],
      envios: []
    }

  })

  const [dataUI, setDataUI] = useState({
    mostrarSideBar: false
  })

  return (
    <AuthContext.Provider value={{ data, setData }}>
      <UiContext.Provider value={{ dataUI, setDataUI }}>
        <AppRouter></AppRouter>
      </UiContext.Provider>
    </AuthContext.Provider>
  );
}

export default UrBexApp;
