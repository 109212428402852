import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
// import { AdminTableScreem } from './AdminTableScreem'
import { AdminTableScreem } from './AdminTableScreen'
import '../dashboard/dashboardScreen.css'

import adduser from '../../assets/add-user.svg'
import { PaginationTable } from '../dashboard/PaginationTable'
import { buscarUsuariosParaTabla, obtenerUsuarios } from '../../services/adminRequest'
import { AuthContext } from '../../auth/AuthContext'

export const AdminDashboarScreen = () => {

   const [buscarUsuario, setBuscarUsuario] = useState('')

   const [cantidadUsuaros, setCantidadUsuarios] = useState(0)

   const { setData } = useContext(AuthContext)

   const handleKeyUp = (ev) => {

      if (ev.code === "Escape") {
         setBuscarUsuario("")

         obtenerUsuarios()
            .then(response => {

               if (response.ok) {

                  setCantidadUsuarios(response.cdt)

                  setData((store) => ({
                     ...store,
                     administrador: {
                        ...store.administrador,
                        usuarios: response.data,
                        usuarioActive: null
                     }
                  }))

               }

            })

         return false

      }

      buscarUsuariosParaTabla(buscarUsuario)
         .then(response => {

            if (response.ok) {

               if (response.data.length > 0) {

                  setData((state) => ({
                     ...state,
                     administrador: {
                        ...state.administrador,
                        usuarios: response.data
                     }
                  }))
               }

            }
         })

   }

   const handleChange = (ev) => {
      setBuscarUsuario(ev.target.value)
   }

   return (
      <div className="urbex__contenido-envio urbex__contenido-admin">

         <div className="urbex__contenido-titulo-info">

            <h1 className="urbex__titulo font-rubik">Usuarios</h1>

            <div className="urbex__info">
               <input
                  type="text"
                  className="urbex__usuarios-buscador"
                  placeholder="Buscar usuario..."
                  onKeyUp={handleKeyUp}
                  value={buscarUsuario}
                  onChange={handleChange}
               />
               <Link to="/admin/nuevousuario" className="urbex__link-agregar-usuarios"><img src={adduser} alt="adduser" /> <span>Agregar usuario</span></Link>
            </div>
         </div>

         <AdminTableScreem setCantidadUsuarios={setCantidadUsuarios}></AdminTableScreem>

         {
            (buscarUsuario) ? '' :
               <PaginationTable tipo="usuarios" cantidadPaginas={cantidadUsuaros}></PaginationTable>
         }



      </div>
   )
}
