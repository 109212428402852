import React from 'react'
import './ui.css'

export const Loader = () => {
   return (
      <div className="urbex__content-loader">
         <div className="urbex__cargando"></div>
      </div>
   )
}
