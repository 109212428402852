export const getTokenRenew = (formValues) => {

   const baseURL = process.env.REACT_APP_API_URL

   const apiURL = `${baseURL}/auth/renew`

   const token = localStorage.getItem("token") || ''

   return fetch(apiURL, {
      method: 'GET',
      headers: {
         "x-token": token
      },
      body: JSON.stringify(formValues)
   })
      .then(response => response.json())
      .then(resp => {

         return resp
      })

}