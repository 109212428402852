import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../../auth/AuthContext'
import { obtenerUsuarios } from '../../services/adminRequest'

import { AdminRowData } from './AdminRowData'

export const AdminTableScreem = ({ setCantidadUsuarios }) => {

   const { data, setData } = useContext(AuthContext)

   const { administrador: { usuarios } } = data

   useEffect(() => {

      obtenerUsuarios()
         .then(response => {

            if (response.ok) {

               setCantidadUsuarios(response.cdt)

               setData((store) => ({
                  ...store,
                  administrador: {
                     ...store.administrador,
                     usuarios: response.data,
                     usuarioActive: null
                  }
               }))

            }

         })



   }, [setData, setCantidadUsuarios])

   return (
      <>
         <div className="urbex__table-envio">
            <table>
               <thead>
                  <tr>
                     <th>Código</th>
                     <th>Nombres</th>
                     <th>Apellidos</th>
                     <th>Creación</th>
                     <th>Tipo</th>
                     <th>Opciones</th>
                  </tr>
               </thead>
               <tbody>
                  <AdminRowData dataUsuarios={usuarios}></AdminRowData>
               </tbody>

            </table>
         </div>
      </>
   )
}
