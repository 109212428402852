import React from 'react'

export const ListaBusqueda = ({ datosBusqueda, handleDatoOnClick }) => {

   return (
      <ul>
         {datosBusqueda.map(dato => (
            <li data-id={dato.id} onClick={() => { handleDatoOnClick(dato) }} key={dato.id}>{dato.nombre} {dato.apellidos}</li>
         ))}
      </ul>
   )
}
