import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import logo from '../../assets/logo.svg'
import './ui.css';
import { UiContext } from './UiContext';

export const SideBar = () => {

   const { dataUI: { mostrarSideBar }, setDataUI } = useContext(UiContext)

   const handleCerrarMenu = () => {
      setDataUI((state) => ({
         ...state,
         mostrarSideBar: false
      }))
   }

   return (
      <div className={(mostrarSideBar) ? 'urbex__sidebar active' : 'urbex__sidebar'}>
         <div className="urbex__sidebar-logo">
            <img src={logo} alt="logo" />
         </div>

         <div className="urbex__sidebar-titulo">
            <p>Plataforma de Usuarios</p>
         </div>

         <div className="urbex__sidebar-menu">
            <NavLink activeClassName="active" exact to="/panel" onClick={handleCerrarMenu}><i className="fas fa-truck"></i> Mis envíos</NavLink>
            <NavLink activeClassName="active" exact to="/panel/datos-personales" onClick={handleCerrarMenu}><i className="fas fa-user"></i> Perfil</NavLink>
         </div>
      </div>
   )
}
