import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import adduser from '../../assets/add-user.svg'
import adjunto from '../../assets/adjunto.svg'
import { buscarUsuario, guardarEnvio } from '../../services/adminRequest'
import { ListaBusqueda } from '../ui/ListaBusqueda'
import { useHistory } from 'react-router'
import Swal from 'sweetalert2'

import distritos from '../../data/distritosLima'

export const AdminNuevoEnvio = () => {

   const history = useHistory()

   const distritosLima = distritos

   const contentSearch = useRef(null)

   const [userValue, setUserValue] = useState("")

   const [dataBusqueda, setDataBusqueda] = useState([])

   const [formValues, setFormValue] = useState({
      estado: "",
      codigo: "",
      desde: "",
      hasta: "",
      fregistro: "",
      fenvio: "",
      usuario: "",
      usuariocorreo: "",
      destinatario: "",
      numdestinatario: "",
      filefactura: "",
      filecargo: "",
   })

   const { codigo, fregistro, fenvio, usuario, usuariocorreo, destinatario, numdestinatario, filefactura, filecargo } = formValues

   const handleInputChange = ({ target }) => {

      if (target.name === "filefactura" || target.name === "filecargo") {
         setFormValue({
            ...formValues,
            [target.name]: target.files[0] || ''
         })
      } else {
         setFormValue({
            ...formValues,
            [target.name]: target.value
         })

      }

   }

   const handleSubmit = (ev) => {

      ev.preventDefault()

      if (formValues.estado === '') {

         Swal.fire("", "No seleccionó el estado del envio.", "warning")
         return false;
      }

      if (formValues.codigo === '') {

         Swal.fire("", "El codigo de envio esta vacio.", "warning")
         return false;
      }

      if (formValues.desde === '' || formValues.hasta === '') {

         Swal.fire("", "Seleccione los campos desde o hasta.", "warning")
         return false;
      }

      if (!(new Date(formValues.fenvio) >= new Date(formValues.fregistro))) {

         Swal.fire("", "La fecha de envio debe ser igual o mayor a la fecha de registro.", "warning")
         return false;

      }

      if (formValues.usuario === '') {

         Swal.fire("", "No coloco ningun usuario", "warning")
         return false;

      }

      if (formValues.destinatario === '' || formValues.numdestinatario === '') {

         Swal.fire("", "Los campos destinatario y/o Numero de contacto son obligatorios", "warning")
         return false;

      }

      Swal.fire({
         title: "Cargando..",
         text: "Por favor esperar",
         allowOutsideClick: false,
         didOpen: () => {
            Swal.showLoading()
         }
      })

      guardarEnvio(new FormData(ev.target))
         .then(response => {

            if (response.ok) {
               Swal.close()
               Swal.fire("", response.msg, "success")
                  .then(result => {
                     if (result.isConfirmed) {
                        history.replace("/admin/envios")
                     }
                  })

            } else {
               Swal.close()
               Swal.fire("Error", response.msg, "error")

            }
         })

   }

   const handleSearch = (ev) => { //Event KeyUp

      if (ev.key === "Escape") {

         ev.target.value = ""
         contentSearch.current.classList.remove("activeSearch")

      } else {

         if (ev.target.value === "") {

            contentSearch.current.classList.remove("activeSearch")
         }

         else {

            buscarUsuario(ev.target.value)
               .then(respUsuarios => {

                  setDataBusqueda(respUsuarios.data)

                  contentSearch.current.classList.add("activeSearch")

               })

         }

      }

   }

   const handleChangeUsuario = (ev) => {
      setUserValue(ev.target.value)
   }

   const handleDatoClick = (usuario) => {

      const codigoUser = usuario.id;
      const nombreUsuer = `${usuario.nombre} ${usuario.apellidos}`
      const correousuario = usuario.correo


      setFormValue({
         ...formValues,
         usuario: codigoUser,
         usuariocorreo: correousuario
      })
      setUserValue(nombreUsuer)

      contentSearch.current.classList.remove("activeSearch")
   }

   return (
      <div className="urbex__contenido-envio animate__animated animate__fadeIn">

         <h1 className="urbex__titulo font-rubik">Nuevo envío</h1>

         <div className="urbex__form-profile">

            <form method="POST" onSubmit={handleSubmit} autoComplete="off">

               <div className="urbex__form-group">

                  <label className="urbex__label-titulo">Estado de envío:</label>

                  <div className="urbex__inputs urbex__inputs-radio">

                     <div className="urbex__radio">
                        <input name="estado" id="tipo_procesando" value="procesando" onChange={handleInputChange} type="radio" /> <label htmlFor="tipo_procesando">Procesando</label>
                     </div>

                     <div className="urbex__radio">
                        <input name="estado" id="tipo_enviando" value="enviando" onChange={handleInputChange} type="radio" /> <label htmlFor="tipo_enviando">Enviando</label>
                     </div>

                     <div className="urbex__radio">
                        <input name="estado" id="tipo_entregado" value="entregado" onChange={handleInputChange} type="radio" /> <label htmlFor="tipo_entregado">Entregado</label>
                     </div>

                  </div>

               </div>

               <div className="urbex__form-inputs-full">
                  <div className="urbex__input-full">
                     <label htmlFor="codigo">Codigo de envio</label>
                     <input type="text" name="codigo" value={codigo} onChange={handleInputChange} id="codigo" />
                  </div>
               </div>

               <div className="urbex__form-group">
                  <div className="urbex__inputs basis">
                     <label htmlFor="desde">Desde</label>
                     <select name="desde" onChange={handleInputChange} id="desde">

                        <option value="" disabled>Seleccionar</option>

                        {distritosLima.map(distrito => (
                           <option value={distrito.toLowerCase()} key={distrito}>{distrito}</option>
                        ))}

                     </select>
                  </div>
                  <div className="urbex__inputs basis">
                     <label htmlFor="hasta">Hasta</label>
                     <select name="hasta" onChange={handleInputChange} id="hasta">

                        <option value="" disabled>Seleccionar</option>

                        {distritosLima.map(distrito => (
                           <option value={distrito.toLowerCase()} key={distrito}>{distrito}</option>
                        ))}

                     </select>
                  </div>
               </div>
               <div className="urbex__form-group">
                  <div className="urbex__inputs">
                     <label htmlFor="fregistro">Fecha de registro</label>
                     <input type="date" name="fregistro" value={fregistro} onChange={handleInputChange} id="fregistro" />
                  </div>
                  <div className="urbex__inputs">
                     <label htmlFor="fenvio">Fecha de envío</label>
                     <input type="date" name="fenvio" value={fenvio} onChange={handleInputChange} id="fenvio" />
                  </div>

               </div>

               <div className="urbex__form-inputs-full">

                  <div className="urbex__input-full">
                     <label htmlFor="usuariomostrar">Usuario que envía <Link to="/admin/nuevousuario"><img src={adduser} alt="agregar" /> Añadir</Link></label>

                     <input type="text" name="usuariomostrar" value={userValue} onChange={handleChangeUsuario} onKeyUp={handleSearch} id="usuariomostrar" />
                     <input type="hidden" name="usuario" value={usuario} onChange={handleInputChange}></input>
                     <input type="hidden" name="usuariocorreo" value={usuariocorreo} onChange={handleInputChange}></input>
                  </div>

                  <div className="urbex__content-search" ref={contentSearch}>
                     <ListaBusqueda datosBusqueda={dataBusqueda} handleDatoOnClick={handleDatoClick}></ListaBusqueda>
                  </div>

               </div>

               <div className="urbex__form-group">
                  <div className="urbex__inputs">
                     <label htmlFor="destinatario">Destinatario</label>
                     <input type="text" name="destinatario" value={destinatario} onChange={handleInputChange} id="destinatario" />
                  </div>
                  <div className="urbex__inputs">
                     <label htmlFor="numdestinatario">Número de contacto</label>
                     <input type="text" name="numdestinatario" value={numdestinatario} onChange={handleInputChange} id="numdestinatario" />
                  </div>

               </div>

               <div className="urbex__linea-gruesa"></div>

               <div className="urbex__form-group adjunto">

                  <div className="urbex__adjunto">
                     <p>Adjuntar comprobante o factura</p>
                     <label htmlFor="factura">
                        <img src={adjunto} alt="" /> {(filefactura) ? 'Cambiar archivo adjunto' : 'Adjuntar archivo'}
                        <input style={{ "display": "none" }} name="filefactura" value={filefactura.filename} onChange={handleInputChange} id="factura" type="file" />
                     </label>
                  </div>

                  <div className="urbex__adjunto">
                     <p>Adjuntar cargo</p>
                     <label htmlFor="cargo">
                        <img src={adjunto} alt="" /> {(filecargo) ? 'Cambiar archivo adjunto' : 'Adjuntar archivo'}
                        <input style={{ "display": "none" }} name="filecargo" value={filecargo.filename} onChange={handleInputChange} id="cargo" type="file" />
                     </label>
                  </div>

               </div>

               <div className="urbex__form-group-button">
                  <button
                     type="submit"
                  >Guardar</button>
               </div>

            </form>

         </div>

      </div>
   )
}
